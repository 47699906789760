import React from 'react';
import { useQuery } from 'react-query';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { adminFetcher } from '../../../plugins/react-query';
import { tabSelectRoomTypeIdState } from '../../../plugins/ridge';
import { RoomType } from '../../../types';
import { RoomTypeTab } from '../../components/RoomTypeTab';
import { TopNavBar } from '../../components/TopNavBar';
import { ReservationCalendar } from './ReservationCalendar';
import { ReservationList } from './ReservationList';

export const ReservationMain = () => {
  const roomTypeId = tabSelectRoomTypeIdState.useValue();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const { data: roomTypes } = useQuery<RoomType[]>(
    '/admin/room-types/all',
    adminFetcher
  );

  const roomType = roomTypes?.find((roomType) => roomType.id === roomTypeId);

  if (!roomTypes) {
    return <></>;
  }

  return (
    <div>
      <TopNavBar
        title="전체 예약현황"
        subTitle={`${
          pathname.endsWith('/calendar') ? '리스트로 보기' : '캘린더로 보기'
        }`}
        onClick={() =>
          push(
            `${
              pathname.endsWith('/calendar')
                ? `/admin/reserve/list`
                : `/admin/reserve/calendar`
            }`
          )
        }
      />
      {/* <RoomTypeTab roomTypes={roomTypes} roomTypeId={roomTypeId} /> */}
      <Switch>
        <Route
          path="/admin/reserve/list"
          render={() => <ReservationList roomType={roomType || roomTypes[0]} />}
        />
        <Route
          path="/admin/reserve/calendar"
          render={() => (
            <ReservationCalendar roomType={roomType || roomTypes[0]} />
          )}
        />
        <Route path="/admin/reserve">
          <Redirect to="/admin/reserve/calendar" />
        </Route>
      </Switch>
    </div>
  );
};
