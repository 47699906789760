import moment, { Moment } from 'moment';
import React, { FC, useState } from 'react';
import { Timeslot } from '../../types';
import { toast } from 'react-toastify';

interface IsOpenedCalendarProps {
  timeslots?: Timeslot[];
  selectedDates?: any;
  setSelectDate: (data: string) => void;
  setSelectedDates: (data: any) => void;
  setMonth: (data: Moment) => void;
  setOpenedRoomIds: (roomIds: number[]) => void;
}

export const IsOpenedCalendar: FC<IsOpenedCalendarProps> = ({
  timeslots,
  selectedDates,
  setSelectDate,
  setSelectedDates,
  setMonth,
  setOpenedRoomIds,
}) => {
  const [getMoment, setMoment] = useState(moment());
  const today = getMoment;
  const [selectedDate, setSelectedDate] = useState<string>(
    today.startOf('day').format()
  );

  // 캘린더 날짜 및 요일 설정
  const firstWeek = today.clone().startOf('month').week();
  const lastWeek =
    today.clone().endOf('month').week() === 1
      ? 53
      : today.clone().endOf('month').week();
  const dates = ['일', '월', '화', '수', '목', '금', '토'];

  const handleDateSelection = (date: string) => {
    const isReservedTimeslot = timeslots?.some(
      (timeslot) =>
        moment(timeslot.checkIn).startOf('day').format() === selectedDate &&
        timeslot.isReserved
    );

    if (isReservedTimeslot) {
      return toast.error('해당 날짜는 이미 예약이 되어있습니다.');
    } else {
      // 예약이 없는 경우에만 날짜를 선택하거나 선택 해제
      setSelectedDates((prevDates: string[]) => {
        if (prevDates.includes(date)) {
          // 이미 선택된 날짜라면 배열에서 제외
          return prevDates.filter((d) => d !== date);
        } else {
          // 선택되지 않은 날짜라면 배열에 추가
          return [...prevDates, date];
        }
      });
    }
  };
  // 캘린더 부분
  const calendarArr = () => {
    let result: any[] = [];
    for (let week = firstWeek; week <= lastWeek; week++) {
      result = result.concat(
        <tr key={week}>
          {Array(7)
            .fill(0)
            .map((_, index) => {
              let days = today
                .clone()
                .startOf('year')
                .week(week)
                .startOf('week')
                .add(index, 'day');
              const isSameTimeslot = timeslots?.filter(
                (timeslot) =>
                  days.isSame(timeslot.checkIn, 'date') && timeslot.isOpened
              );

              const isReservedTimeslot = timeslots?.some(
                (timeslot) =>
                  days.isSame(timeslot.checkIn, 'date') && timeslot.isReserved
              );

              const isOpenedOnDate = timeslots?.some(
                (timeslot) =>
                  days.isSame(timeslot.checkIn, 'date') && timeslot.isOpened
              );

              if (
                days.format('MM') !== today.format('MM') ||
                moment().format('YYYYMMDD') > days.format('YYYYMMDD')
              ) {
                return (
                  <td key={index} className="text-gray-300 cursor-pointer">
                    <span>{days.format('D')}</span>
                  </td>
                );
              } else {
                return (
                  <td
                    key={index}
                    className={`cursor-pointer p-2 rounded-lg ${
                      selectedDates?.includes(days.format())
                        ? !isOpenedOnDate
                          ? 'bg-blue-100 border border-blue-500 font-bold text-blue-700'
                          : 'bg-gray-100 border border-gray-500 font-bold text-gray-300'
                        : isReservedTimeslot
                        ? 'text-red-500 font-bold' // 예약된 경우 빨간색 텍스트
                        : isOpenedOnDate
                        ? 'text-blue-500'
                        : 'text-brand-3'
                    }`}
                    onClick={() => {
                      if (isReservedTimeslot) {
                        return toast.error(
                          '해당 날짜는 이미 예약이 되어있습니다.'
                        );
                      } else {
                        handleDateSelection(days.format());

                        if (isSameTimeslot && isSameTimeslot.length > 0) {
                          setOpenedRoomIds(
                            isSameTimeslot
                              .filter((timeslot) => !timeslot.isReserved)
                              .map((timeslot) => timeslot.room.id)
                          );
                          setSelectedDate(days.format());
                          setSelectDate(days.format());
                        } else {
                          setOpenedRoomIds([]);
                          setSelectedDate(days.format());
                          setSelectDate(days.format());
                        }
                      }
                    }}
                  >
                    <span>{days.format('D')}</span>
                    {/* <div className="text-12">({isSameTimeslot?.length})</div> */}
                  </td>
                );
              }
            })}
        </tr>
      );
    }
    return result;
  };

  return (
    <div className="px-4">
      <div className="text-center">
        <div className="text-center my-9 space-x-20 text-19 font-semibold text-gray-700">
          <button
            onClick={() => {
              setMonth(getMoment.clone().subtract(1, 'month'));
              setMoment(getMoment.clone().subtract(1, 'month'));
            }}
          >
            {`<`}
          </button>
          <span>{today.format('YYYY 년 MM 월')}</span>
          <button
            onClick={() => {
              setMonth(getMoment.clone().add(1, 'month'));
              setMoment(getMoment.clone().add(1, 'month'));
            }}
          >
            {`>`}
          </button>
        </div>

        <table className="w-full h-screen-35">
          <tbody>
            <tr>
              {dates.map((date, idx) => (
                <td key={idx} className="text-gray-400">
                  <span>{date}</span>
                </td>
              ))}
            </tr>
          </tbody>
          <tbody>{calendarArr()}</tbody>
        </table>
      </div>
    </div>
  );
};
