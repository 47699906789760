import React from 'react';
import { BottomNavBar } from '../../components/BottomNavBar';
import { TopBar } from '../../components/TopBar';
import { FooterBar } from '../../components/FooterBar';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { useQuery } from 'react-query';
import { Description, PageTitle } from '../../types';
import { fetcher } from '../../plugins/react-query';
import { tabSelectPageTitleState } from '../../plugins/ridge';
import { Button } from '../../components/Button';

export const ContactMain = () => {
  tabSelectPageTitleState.set(PageTitle.CONTACT);
  const pageTitle = tabSelectPageTitleState.useValue();
  const { data: description } = useQuery<Description>(
    `/descriptions?title=${pageTitle}`,
    fetcher
  );

  return (
    <>
      <TopBar text="CONTACT" backButton />
      <FroalaEditorView model={description?.content} />
      <FooterBar />
      <BottomNavBar />
      <div className="pt-16">
        <Button
          type="button"
          to="/reservation"
          text="예약하기"
          className="filled-brand-1 h-16 fixed bottom-20 w-full max-w-md"
        />
      </div>
    </>
  );
};
