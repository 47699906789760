import moment from 'moment';
import { stringify } from 'qs';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Button } from '../../../components/Button';
import { Select } from '../../../components/Select';
import { adminFetcher } from '../../../plugins/react-query';
import { tabSelectRoomTypeIdState } from '../../../plugins/ridge';
import { Reservation, ReservationState, RoomType } from '../../../types';
import { ReservationCard } from '../../components/ReservationCard';
import { RoomTypeTab } from '../../components/RoomTypeTab';
import { Search } from '../../components/Search';
import { TopNavBar } from '../../components/TopNavBar';

export const ReservationRealTime = () => {
  const roomTypeId = tabSelectRoomTypeIdState.useValue();
  const [reservationState, setReservationState] = useState<
    ReservationState | string
  >();

  const [roomId, setRoomId] = useState<number>();
  const [q, setQ] = useState<string>('');

  const { data: roomTypes } = useQuery<RoomType[]>(
    '/admin/room-types/all',
    adminFetcher
  );

  const roomType = roomTypes?.find((roomType) => roomType.id === roomTypeId);

  const { data: reservations } = useQuery<Reservation[]>(
    `/admin/reservations/roomType/${roomType?.id}/real-time?${stringify({
      reservationState: reservationState,
      roomId: roomId,
      q: q,
    })}`,
    adminFetcher,
    { enabled: !!roomType }
  );

  return (
    <div>
      <TopNavBar title="실시간 예약현황" />
      {/* <RoomTypeTab roomTypes={roomTypes} roomTypeId={roomTypeId} /> */}

      <div className="space-y-3 px-6 pt-6">
        <div className="grid grid-cols-2 gap-x-3">
          <Select
            value={reservationState}
            onChange={(e) =>
              setReservationState(e.target.value as ReservationState)
            }
          >
            <option value={''} selected disabled>
              전체상태
            </option>
            <option value={ReservationState.BEFORE_DEPOSIT}>입금전</option>
            <option value={ReservationState.APPLICATION_FOR_RESERVATION}>
              예약신청
            </option>
            <option value={ReservationState.CANCELLATION_REQUEST}>
              취소신청
            </option>
          </Select>
          <Select
            value={roomId}
            onChange={(e) => setRoomId(Number(e.target.value))}
          >
            <option selected disabled>
              전체 룸
            </option>
            {roomType?.rooms.map((room) => (
              <option key={room.id} value={room.id}>
                {room.title}
              </option>
            ))}
          </Select>
        </div>

        <div className="grid grid-cols-4 gap-x-3">
          <div className="col-span-3">
            <Search
              placeholder="검색"
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
          </div>
          <Button text="검색" className="filled-brand-1 text-sm h-full" />
        </div>
      </div>
      {!reservations || reservations.length === 0 ? (
        <p className="mt-5 pl-5">해당 날짜의 예약이 없습니다.</p>
      ) : (
        <>
          {reservations
            .sort((a: any, b: any) => {
              const now = moment().set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              });

              const aPaidAt = moment(a.paidAt).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              });

              const aCancelRequestedAt = moment(a.cancelRequestedAt).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              });

              if (
                now.isSame(aPaidAt) ||
                (a.cancelRequestedAt && now.isSame(aCancelRequestedAt))
              ) {
                return -1;
              } else if (
                a.paidAt > b.paidAt ||
                (a.cancelRequestedAt &&
                  a.cancelRequestedAt > b.cancelRequestedAt)
              ) {
                return -1;
              } else {
                return 0;
              }
            })
            .map((reservation) => (
              <ReservationCard reservation={reservation} />
            ))}
        </>
      )}
    </div>
  );
};
