import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { TextField } from '../../../components/TextField';
import { TopBar } from '../../../components/TopBar';
import { adminApi } from '../../../plugins/axios';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { useQuery } from 'react-query';
import { Gallery, RoomType } from '../../../types';
import { adminFetcher } from '../../../plugins/react-query';
import { tabSelectRoomTypeIdState } from '../../../plugins/ridge';
import { EditModal } from '../../components/modal/editModal';

interface FormValues {
  roomTypeId: number;
  sorted: number;
  file: any;
}
export const GalleryEdit = () => {
  const key = uuidv4();
  const roomTypeId = tabSelectRoomTypeIdState.useValue();
  const { id } = useParams<{ id: string }>();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();
  const { data: roomTypes } = useQuery<RoomType[]>(
    '/admin/room-types/all',
    adminFetcher
  );
  const { push } = useHistory();
  const { data: galleryImage } = useQuery<Gallery>(
    `/admin/gallery/edit/${id}`,
    adminFetcher,
    { enabled: !!id }
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [productPhoto, setProductPhoto] = useState<any>(
    galleryImage?.imageFile
  );
  const [sorted, setSorted] = useState(galleryImage?.sorted);
  const [updatePhot, setUpdatePhoto] = useState<any>('');

  useEffect(() => {
    if (!galleryImage) return;
    setProductPhoto(galleryImage?.imageFile);
    setSorted(galleryImage.sorted);
  }, [setSorted, setValue, galleryImage]);

  return (
    <>
      <TopBar text="이미지 수정하기" backButton />
      <div className="p-6 space-y-4 flex-1 flex flex-col">
        <label htmlFor={key}>
          <div className="relative pb-full rounded-md border-2 border-grey-5">
            {productPhoto ? (
              <>
                <img
                  className="relative pb-full"
                  src={productPhoto ? productPhoto : ''}
                  alt=""
                />

                <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-2 left-2">
                  사진 수정하기
                </div>
              </>
            ) : (
              <div className=" md:pr-1 md:py-6">
                <div className="flex flex-col justify-center items-center space-y-2 w-full h-full">
                  <FileUploadImg />
                  <div className="text-sm text-gray-500 text-center pt-1">
                    이미지(jpg, png) 파일을 업로드해주세요.
                    <br />
                    (최대 1장)
                  </div>
                </div>
              </div>
            )}
          </div>
        </label>
        <input
          type="file"
          id={key}
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            if (!e.target.validity.valid) return;
            const file = e.target.files?.item(0);
            if (file) {
              setProductPhoto(URL.createObjectURL(file));
              setUpdatePhoto(file);
            }
          }}
        />
        <div className="space-y-1">
          <TextField
            type="number"
            label="우선순위(숫자)"
            value={sorted}
            onChange={(e) => {
              setSorted(e.target.value);
            }}
          />
        </div>
        <div className="flex-1" />
        <div className="space-y-3">
          <EditModal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            refetch={() => ''}
          />
          <Button
            text={'저장하기'}
            disabled={!productPhoto || !sorted}
            className="filled-brand-1 w-full"
            onClick={handleSubmit(async (data) => {
              setIsOpen(true);

              data.roomTypeId = +roomTypeId;
              data.sorted = sorted;
              data.file = updatePhot;

              const formData = new FormData();
              Object.entries(data).forEach(([name, value]) =>
                formData.append(name, value)
              );

              await adminApi
                .post(`/admin/gallery/update/${id}`, formData)
                .catch((e) => console.log(e));

              push(`/admin/gallery/edit/${id}`);
            })}
          />
          <Button
            text="삭제하기"
            className="outlined-brand-1 w-full"
            onClick={handleSubmit(async () => {
              const isDelete = window.confirm('정말 삭제하시겠습니까?');
              try {
                if (isDelete) {
                  await adminApi.patch(`/admin/gallery/delete/${id}`);
                  toast.success('변경사항이 저장되었습니다.');

                  push('/admin/gallery/editor');
                }
              } catch (e) {}
            })}
          />
        </div>
      </div>
    </>
  );
};
