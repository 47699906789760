import React, { useState } from 'react';
import { TopBar } from '../../components/TopBar';
import { Description } from '../../components/Description';
import { useLocation, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import { useQuery } from 'react-query';
import { Gallery } from '../../types';
import { fetcher } from '../../plugins/react-query';

export const Lounge = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<any>();
  const { data: galleryListMaru } = useQuery<Gallery[]>(
    '/gallery/maru',
    fetcher
  );

  const { data: galleryListHaru } = useQuery<Gallery[]>(
    '/gallery/haru',
    fetcher
  );

  return (
    <>
      <TopBar text="GALLERY" backButton />
      <div className="p-6">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {galleryListMaru &&
            galleryListHaru &&
            (id == '1'
              ? galleryListMaru.map((gallery, i) => (
                  <SwiperSlide className="h-screen-25 relative" key={i}>
                    <img
                      src={gallery.imageFile}
                      alt=""
                      className="w-full h-full object-cover absolute"
                    />
                  </SwiperSlide>
                ))
              : galleryListHaru.map((gallery, i) => (
                  <SwiperSlide className="h-screen-25 relative" key={i}>
                    <img
                      src={gallery.imageFile}
                      alt=""
                      className="w-full h-full object-cover absolute"
                    />
                  </SwiperSlide>
                )))}
        </Swiper>

        <Description className="pb-0" title={state.title} />
      </div>
    </>
  );
};
