import React from 'react';
import logo from '../assets/svg/logo.svg';

export const FooterBar = () => {
  return (
    <div>
      <div className="border-t border-gray-700 px-6 py-6 bg-neutral-100">
        <img src={logo} className="h-4" alt="logo" />

        <div className="text-13 space-x-3 mt-5">
          <a
            href="https://prairie-porcupine-6b9.notion.site/1a99dfc8163b4030afa18dc83ac58e6f?pvs=4"
            target="_blank"
            rel="noreferrer"
          >
            <span className="font-bold">이용약관</span>
          </a>
          <a
            href="https://prairie-porcupine-6b9.notion.site/31a745c76f114d3aae32b7fb3cc9ed61?pvs=4"
            target="_blank"
            rel="noreferrer"
          >
            <span className="font-bold">개인정보취급방침</span>
          </a>
        </div>

        <p className="text-12 pt-4 text-gray-700">
          Copyright ⓒ 2024. Villa Nute. all rights reserved. <br />
          <br />
          대표명 : 최진희
          <br />
          연락처 : 0504-0904-2792
          <br />
          사업자등록번호: 334-43-00889
          <br />
          통신판매업신고 번호 : 제 2023-경기가평-230 호 <br />
          이메일 : villanute@gmail.com <br />
          경기 가평군 상면 윗다원길 65-142 (덕현리)
        </p>
      </div>
    </div>
  );
};
