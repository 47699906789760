import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { TopBar } from '../../../components/TopBar';
import { adminApi } from '../../../plugins/axios';
import { adminFetcher } from '../../../plugins/react-query';
import { RoomOption } from '../../../types';

interface FormValues {
  title: string;
  description: string;
  price: number;
  roomId: number;
}

export const OptionDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const { data: roomOption, refetch } = useQuery<RoomOption>(
    `admin/room-options/${id}`,
    adminFetcher,
    { enabled: !!id }
  );

  useEffect(() => {
    if (!roomOption) return;
    setValue('title', roomOption.title);
    setValue('description', roomOption.description);
    setValue('price', roomOption.price.toString() as any);
    setValue('roomId', roomOption.roomId);
  }, [roomOption]);

  return (
    <>
      <TopBar text="추가 옵션 상세" backButton />
      <div className="p-6 space-y-4 flex-1 flex flex-col">
        <h3 className="text-xl font-semibold">추가 옵션 상세</h3>
        <TextField
          label="옵션명"
          placeholder="옵션명을 입력해주세요."
          helper={errors.title?.message}
          {...register('title', {
            required: '옵션명을 입력해주세요.',
          })}
        />
        <TextField
          label="수량당 추가금액"
          type="number"
          placeholder="수량당 추가금액을 입력해주세요."
          helper={errors.price?.message}
          {...register('price', {
            required: '수량당 추가금액을 입력해주세요.',
          })}
        />
        <TextArea
          label="상세설명"
          className="h-44"
          placeholder="상세설명을 입력해주세요."
          helper={errors.description?.message}
          {...register('description', {
            required: '상세설명을 입력해주세요.',
          })}
        />
        <div className="flex-1" />
        <div className="space-y-3">
          <Button
            text="저장하기"
            className="filled-brand-1 w-full"
            disabled={
              !watch('title') || !watch('price') || !watch('description')
            }
            onClick={handleSubmit(async (data) => {
              const isEdit = window.confirm('변경사항을 저장하시겠습니까?');
              try {
                if (isEdit) {
                  await adminApi.patch(`/admin/room-options/${id}`, data);
                  toast.success('변경사항이 저장되었습니다.');
                  refetch();
                }
              } catch (e) {
                console.log('결제시 추가 옵션 설정 수정 오류', e);
                toast.error('결제시 추가 옵션 설정 수정 오류');
              }
            })}
          />
          <Button
            text="삭제하기"
            className="outlined-brand-1 w-full"
            onClick={handleSubmit(async () => {
              const isDelete = window.confirm('정말 삭제하시겠습니까?');
              try {
                if (isDelete) {
                  await adminApi.patch(`/admin/room-options/isDisabled/${id}`);
                  toast.success('변경사항이 저장되었습니다.');
                  refetch();
                  push('/admin/rooms/option');
                }
              } catch (e) {
                console.log('결제시 추가 옵션 설정 숨김 오류', e);
                toast.error('결제시 추가 옵션 설정 숨김 오류');
              }
            })}
          />
        </div>
      </div>
    </>
  );
};
