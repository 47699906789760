import moment from 'moment';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { villaNuteThumbnail } from '../dummies';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { Reservation, ReservationState } from '../types';
import { Button } from './Button';

export interface ReservationItemCardProps {
  reservation: Reservation;
}

export const ReservationItemCard: FC<ReservationItemCardProps> = ({
  reservation,
}) => {
  const { push } = useHistory();

  // 사용자가 선택한 날짜 연박일 수 계산
  const countingNights =
    reservation?.checkIn &&
    reservation?.checkOut &&
    moment(reservation?.checkOut).diff(moment(reservation?.checkIn), 'days');

  // 숙소 썸네일
  const roomThumbnail = villaNuteThumbnail.find(
    (thumbnail) => thumbnail.roomId === reservation.roomId
  );

  return (
    <>
      <div
        className={`py-3 space-y-2 ${
          reservation.state === ReservationState.USED && 'opacity-50'
        }`}
        onClick={() =>
          reservation.state === ReservationState.CANCELED
            ? push(`/my/reservation/cancel/${reservation.id}`)
            : push(`/my/reservation/${reservation.id}`)
        }
      >
        <div className="grid grid-cols-4 gap-3">
          <div className="aspect-[1/1] relative">
            <img
              src={roomThumbnail?.image}
              alt=""
              className="w-full h-full object-cover absolute"
            />
          </div>
          <div className="space-y-1 col-span-3">
            <div className="flex justify-between mr-2">
              <p className="text-gray-600">{reservation.roomTitle}</p>
              <p
                className={`${
                  reservation.state === ReservationState.CANCELED ||
                  reservation.state ===
                    ReservationState.RESERVATION_CONFIRMATION
                    ? 'text-gray-600'
                    : reservation.state ===
                        ReservationState.CANCELLATION_REQUEST ||
                      reservation.state === ReservationState.BEFORE_DEPOSIT
                    ? 'text-red-600'
                    : reservation.state ===
                      ReservationState.APPLICATION_FOR_RESERVATION
                    ? 'text-blue-600'
                    : 'text-gray-600'
                }`}
              >
                {reservation.state === ReservationState.CANCELED
                  ? '취소완료'
                  : reservation.state === ReservationState.CANCELLATION_REQUEST
                  ? '취소신청'
                  : reservation.state ===
                    ReservationState.APPLICATION_FOR_RESERVATION
                  ? '예약신청'
                  : reservation.state ===
                    ReservationState.RESERVATION_CONFIRMATION
                  ? '예약완료'
                  : reservation.state === ReservationState.BEFORE_DEPOSIT
                  ? '입금 전'
                  : ''}
              </p>
            </div>
            <p className="font-semibold">
              {utcToLocalFormat(reservation.checkIn, MomentFormat.LLdd)} -{' '}
              {utcToLocalFormat(reservation.checkOut, MomentFormat.LLdd)}
            </p>
            <p className="text-gray-600">
              {' '}
              {countingNights}박 {Number(countingNights) + 1}일
            </p>
          </div>
        </div>
        {(reservation.state === ReservationState.APPLICATION_FOR_RESERVATION ||
          reservation.state === ReservationState.RESERVATION_CONFIRMATION ||
          reservation.state === ReservationState.BEFORE_DEPOSIT) && (
          <Button text="상세보기" className="w-full outlined-black h-12" />
        )}
      </div>
    </>
  );
};
