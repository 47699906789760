import { useState } from 'react';
import { TopNavBar } from '../../components/TopNavBar';
import { Gallery, RoomType } from '../../../types';
import { tabSelectRoomTypeIdState } from '../../../plugins/ridge';
import { useQuery } from 'react-query';
import { adminFetcher } from '../../../plugins/react-query';
import { v4 as uuidv4 } from 'uuid';
import { RoomTypeTab } from '../../components/RoomTypeTab';
import { Button } from '../../../components/Button';
import { useHistory } from 'react-router-dom';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
interface FormValues {
  roomTypeId: number;
  sorted: number;
  file: File;
}

export const GalleryPageEditor = () => {
  const roomTypeId = tabSelectRoomTypeIdState.useValue();
  const { data: roomTypes } = useQuery<RoomType[]>(
    '/admin/room-types/all',
    adminFetcher
  );
  const { data: galleryListMaru } = useQuery<Gallery[]>(
    '/admin/gallery/maru',
    adminFetcher
  );
  const { data: galleryListHaru } = useQuery<Gallery[]>(
    '/admin/gallery/haru',
    adminFetcher
  );
  const { push } = useHistory();
  const key = uuidv4();
  const [index, setIndex] = useState<number>(0);

  if (!galleryListMaru) return null;
  if (!galleryListHaru) return null;

  return (
    <>
      <TopNavBar title="갤러리 관리 설정" />
      {/* <RoomTypeTab roomTypes={roomTypes} roomTypeId={roomTypeId} /> */}

      <div className="p-6">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
          onActiveIndexChange={(swiper) => {
            setIndex(swiper.activeIndex);
          }}
        >
          {roomTypeId == 1
            ? galleryListMaru.map((gallery, i) => (
                <SwiperSlide className="h-screen-25 relative" key={i}>
                  <img
                    src={gallery.imageFile}
                    alt=""
                    className="w-full h-full object-cover absolute"
                  />
                </SwiperSlide>
              ))
            : galleryListHaru.map((gallery, i) => (
                <SwiperSlide className="h-screen-25 relative" key={i}>
                  <img
                    src={gallery.imageFile}
                    alt=""
                    className="w-full h-full object-cover absolute"
                  />
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
      <div className="px-6 pb-3 pt-3 ">
        {roomTypeId == 1 ? (
          <Button
            text="이미지 수정하기"
            className="filled-brand-1 w-full"
            onClick={() =>
              push(`/admin/gallery/edit/${galleryListMaru[index].id}`)
            }
          />
        ) : (
          <Button
            text="이미지 수정하기"
            className="filled-brand-1 w-full"
            onClick={() =>
              push(`/admin/gallery/edit/${galleryListHaru[index].id}`)
            }
          />
        )}
      </div>
      <div className="px-6 pb-6 pt-1/5 ">
        {roomTypeId == 1 ? (
          <Button
            text="이미지 추가"
            className="outlined-brand-1 w-full "
            onClick={() => push(`/admin/gallery/add?roomTypeId=${roomTypeId}`)}
          />
        ) : (
          <Button
            text="이미지 추가"
            className="outlined-brand-1 w-full "
            onClick={() => push(`/admin/gallery/add?roomTypeId=${roomTypeId}`)}
          />
        )}
      </div>
    </>
  );
};
