import moment from 'moment';
import { parse } from 'qs';
import React from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Button } from '../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { PaymentMethod, PaymentOrder } from '../../types';

export const OrderSuccess = () => {
  const { search } = useLocation();
  const { item } = parse(search, { ignoreQueryPrefix: true });

  const { data: paymentOrder } = useQuery<PaymentOrder>(
    `/payment-orders/${Number(item)}`,
    fetcher,
    {
      enabled: !!item,
    }
  );

  // 사용자가 선택한 날짜 연박일 수 계산
  const countingNights =
    paymentOrder?.checkIn &&
    paymentOrder?.checkOut &&
    moment(paymentOrder?.checkOut).diff(moment(paymentOrder?.checkIn), 'days');

  if (!paymentOrder) {
    return <></>;
  }

  return (
    <div className="px-6">
      <div className="text-center my-10">
        <h2 className="text-2xl font-medium">예약 신청 완료</h2>
        <div className="text-sm text-gray-600 space-y-1 mt-5">
          <p>
            {paymentOrder.roomTypeTitle} {paymentOrder.roomTitle} 예약이
            완료되었습니다.
          </p>
          <span className="text-brand-1 text-xs">
            관리자가 확인 후 최종 예약 확정{' '}
          </span>
          <span className="text-xs">
            문자를 보내드립니다.
            <br />
            확인까지 1-3일이 소요될 수 있으니 조금만 기다려주세요.
          </span>
        </div>
      </div>

      <div>
        <h3 className="text-xl font-medium pb-3 border-b mb-3">
          예약 숙소 정보
        </h3>
        <div className="space-y-2">
          <div className="flex justify-between items-center text-lg font-semibold">
            <div className="">숙소명</div>
            <div className="">{paymentOrder.roomTypeTitle}</div>
          </div>
          {[
            {
              label: '객실명',
              content: paymentOrder.roomTitle,
            },
            {
              label: '예약 기간',
              content: `${utcToLocalFormat(
                paymentOrder.checkIn,
                MomentFormat.LLdd
              )} - ${utcToLocalFormat(
                paymentOrder.checkOut,
                MomentFormat.LLdd
              )} ${countingNights}박${Number(countingNights) + 1}일`,
            },
            {
              label: '예약자명',
              content: paymentOrder.reservationName,
            },
            {
              label: '예약자 핸드폰 번호',
              content: paymentOrder.reservationPhoneNumber,
            },
            {
              label: '인원수',
              content: `성인 ${paymentOrder.adult}명 / 아동 ${
                paymentOrder.child ? paymentOrder.child : 0
              }명 / 영아 ${paymentOrder.infant ? paymentOrder.infant : 0}명`,
            },
            {
              label: '추가사항',
              content: paymentOrder.orderRoomOptions.map((orderRoomOption) => (
                <>
                  {orderRoomOption.title}({orderRoomOption.quantity}){' '}
                </>
              )),
            },
            {
              label: '요청사항',
              content: paymentOrder.requestedTerm || '-',
            },
          ].map((e) => (
            <div className="flex justify-between items-center text-sm">
              <div className="text-gray-600 whitespace-pre">{e.label}</div>
              <div
                className="text-gray-900 text-13 text-right"
                style={{ wordBreak: 'keep-all' }}
              >
                {e.content}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="h-3 bg-gray-50 -mx-6 px-6 my-6" />

      <div>
        <h3 className="text-xl font-medium pb-3 border-b mb-3">결제 정보</h3>
        <div className="space-y-2">
          <div className="flex justify-between items-center text-lg font-semibold">
            <div className="">총 결제 금액</div>
            <div className="">
              {paymentOrder.finalPrice
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
            </div>
          </div>
          {[
            {
              label: '결제일시',
              content: utcToLocalFormat(
                paymentOrder.paidAt ?? moment().format(),
                MomentFormat.YYYYMMDDHmm
              ),
            },
            {
              label: '예약 기간',
              content: `${utcToLocalFormat(
                paymentOrder.checkIn,
                MomentFormat.LLdd
              )} - ${utcToLocalFormat(
                paymentOrder.checkOut,
                MomentFormat.LLdd
              )} ${countingNights}박${Number(countingNights) + 1}일`,
            },
            {
              label: '결제방법',
              content:
                paymentOrder.paymentMethod === PaymentMethod.CARD
                  ? '카드결제'
                  : paymentOrder.paymentMethod === PaymentMethod.BANK
                  ? '무통장 입금'
                  : paymentOrder.paymentMethod === PaymentMethod.K_PAY
                  ? '카카오 페이'
                  : paymentOrder.paymentMethod === PaymentMethod.N_PAY
                  ? '네이버 페이'
                  : '-',
            },
          ].map((e) => (
            <div className="flex justify-between items-center text-sm">
              <div className="text-gray-600 whitespace-pre">{e.label}</div>
              <div
                className="text-gray-900 text-13 text-right"
                style={{ wordBreak: 'keep-all' }}
              >
                {e.content}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-10 space-y-3">
        {paymentOrder.paymentMethod === PaymentMethod.BANK && (
          <div className="text-center text-13">
            {/* *무통장입금의 경우 4시간내 미입금시 자동 취소됩니다. */}
          </div>
        )}
        <Button
          text="예약내역 확인하기"
          className="filled-brand-1 w-full"
          to="/my"
        />
        <Button
          text="메인으로"
          className="text-brand-1 w-full"
          to="/reservation"
        />
      </div>
    </div>
  );
};
