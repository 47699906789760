import React from 'react';
import { Description } from '../../components/Description';
import { RoomCard } from '../../components/RoomCard';
import { TopBar } from '../../components/TopBar';
import { useHistory, useParams } from 'react-router-dom';
import { DateSelectFiled } from '../../components/DateSelectFiled';
import { useQuery } from 'react-query';
import { Room, RoomType } from '../../types';
import { fetcher } from '../../plugins/react-query';
import moment from 'moment';
import { Loading } from '../../components/Loading';

export const ReservationRoomsPage = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const checkIn = localStorage.getItem('checkIn');
  const checkOut = localStorage.getItem('checkOut');

  // 선택한 날짜가 없는 경우 일반 객실 정보 불러오기
  const { data: roomType } = useQuery<RoomType>(`/room-types/${id}`, fetcher, {
    enabled: !!id,
  });

  // 선택한 날짜가 있는 경우 선택한 날짜의 예약 가능한 객실 불러오기
  const { data: rooms } = useQuery<Room[]>(
    `/rooms/schedule/${roomType?.id}?checkIn=${moment(
      checkIn
    ).toISOString()}&checkOut=${moment(checkOut).toISOString()}`,
    fetcher,
    {
      enabled: !!checkIn && !!checkOut && !!roomType,
    }
  );

  // 해당 날짜의 객실 별 총 금액 가져오는 api
  const { data: roomPrices } = useQuery<number[]>(
    `/rooms/${id}/price?checkIn=${moment(
      checkIn
    ).toISOString()}&checkOut=${moment(checkOut).toISOString()}`,
    fetcher,
    {
      enabled: !!checkIn && !!checkOut && !!id,
    }
  );

  // 이미 에약된 날짜 필터링
  const roomTimeslotLength = rooms?.map((room) => room.timeslots.length);
  const maxTimeslotLength = Math.max.apply(null, roomTimeslotLength || []);

  if (!roomType || !rooms || rooms.length === 0) {
    return <Loading />;
  }

  return (
    <>
      <TopBar text={roomType?.title || ''} backButton />

      <div className="px-6 pt-6">
        <DateSelectFiled
          checkIn={checkIn}
          checkOut={checkOut}
          onClick={() => push(`/reservation/calendar?roomTypeId=${id}`)}
        />
        <div className="my-8">
          <div className="grid grid-cols-2 gap-5 mt-2">
            {checkIn && checkOut ? (
              <>
                {rooms?.map((room, i) => {
                  const price = roomPrices?.find((_, index) => index === i);

                  return (
                    <RoomCard
                      price={price}
                      checkIn={checkIn}
                      checkOut={checkOut}
                      room={room}
                      key={room.id}
                      maxTimeslotLength={maxTimeslotLength}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {roomType?.rooms?.map((room) => {
                  // 타임슬롯 요일 구하기
                  const findDay = moment().weekday();
                  // 요일별 금액 보여주기
                  const totalPrice = room.timeslots[0].detailPrice
                    ? room.timeslots[0].detailPrice
                    : findDay === 0
                    ? roomType.sundayPrice
                    : findDay === 5
                    ? roomType.fridayPrice
                    : findDay === 6
                    ? roomType.saturdayPrice
                    : roomType.weekdayPrice;

                  return (
                    <RoomCard price={totalPrice} room={room} key={room.id} />
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>

      <Description
        className="px-6 pb-10"
        desc={
          roomType?.id === 1
            ? `오롯이 휴식을 느낄 수 있는 공간을 위하여 \n 숙소 내부에서는 인터넷 연결 및 통신사 연결이 원활하지 않습니다.`
            : `아틴 해우는 하나의 건물을 수직으로 절반씩 나누어 각각의 팀이 3개층씩 사용하는 구조입니다. 두 공간을 나누는 벽체의 방음공사와 계단을 설치하여 이용 시간이 잦은 공간은 최대한 거리를 두어 구성되어 있습니다. (소음 방지를 위해 스피커는 제공하지 않습니다.) 하지만 구조 벽체는 하나로 연결되어 있기 때문에 소음이 옆 공간으로 전달될 수 있으니 주의 및 양해 바랍니다. (매너 타임 22:00 – 08:00)`
        }
      />
    </>
  );
};
