import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { Chip } from '../../../components/Chip';
import { adminApi } from '../../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { adminFetcher } from '../../../plugins/react-query';
import { tabSelectRoomTypeIdState } from '../../../plugins/ridge';
import { RoomType, Timeslot } from '../../../types';
import { IsOpenedCalendar } from '../../components/IsOpenedCalendar';
import { RoomTypeTab } from '../../components/RoomTypeTab';
import { TopNavBar } from '../../components/TopNavBar';

export const ReservationHolidayMain = () => {
  const roomTypeId = tabSelectRoomTypeIdState.useValue();
  const [openedRoomIds, setOpenedRoomIds] = useState(new Set<number>());
  const [closedRoomIds, setClosedRoomIds] = useState(new Set<number>());
  const today = moment();
  const [selectDate, setSelectDate] = useState<string>(
    today.startOf('day').format()
  );

  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [month, setMonth] = useState(moment());

  const { data: roomTypes } = useQuery<RoomType[]>(
    '/admin/room-types/all',
    adminFetcher
  );

  const from = month.startOf('month').format('YYYY-MM-DD');
  const to = month.endOf('month').format('YYYY-MM-DD');

  const { data: timeslots, refetch } = useQuery<Timeslot[]>(
    `/admin/timeslots/roomTypeId/${roomTypeId}?from=${from}&to=${to}`,
    adminFetcher,
    {
      enabled: !!roomTypeId,
    }
  );

  // 방 열기 막기 가능한 timeslot 찾기
  const findRoomTypes = roomTypes?.find(
    (roomType) => roomType.id === roomTypeId
  );
  const findPossibleTimeslots = findRoomTypes?.rooms.map((room) =>
    timeslots?.find(
      (timeslot) =>
        moment(timeslot.checkIn).startOf('day').format() === selectDate &&
        !timeslot.isReserved &&
        room.id === timeslot.room.id
    )
  );
  const possibleTimeslots = findRoomTypes?.rooms
    .filter((room) =>
      findPossibleTimeslots?.find((r) => room.id === r?.room.id)
    )
    .map((room) => room.id);

  // 오늘 날짜의 timeslot 찾기
  const todayTimeslots = timeslots?.filter(
    (timeslot) =>
      moment(timeslot.checkIn).startOf('day').format() === selectDate
  );
  const isOpenedTimeslot = todayTimeslots?.filter(
    (timeslot) => timeslot.isOpened
  );

  // 페이지 첫 들어왔을때 오늘 날짜로 활성된 Timeslot 보여주기
  useEffect(() => {
    const selectedOpenTimeslot = timeslots?.filter(
      (timeslot) =>
        moment(timeslot.checkIn).startOf('day').format() === selectDate &&
        timeslot.isOpened &&
        !timeslot.isReserved
    );
    const selectedCloseTimeslot = timeslots?.filter(
      (timeslot) =>
        moment(timeslot.checkIn).startOf('day').format() === selectDate &&
        !timeslot.isOpened &&
        !timeslot.isReserved
    );

    if (selectedOpenTimeslot && selectedOpenTimeslot.length > 0) {
      setOpenedRoomIds(
        new Set<number>(
          selectedOpenTimeslot.map((timeslot) => timeslot.room.id)
        )
      );
    }
    if (selectedCloseTimeslot && selectedCloseTimeslot.length >= 0) {
      setClosedRoomIds(
        new Set<number>(
          selectedCloseTimeslot.map((timeslot) => timeslot.room.id)
        )
      );
    }
  }, [selectDate, timeslots]);

  if (!roomTypes) {
    return <></>;
  }

  // console.log('selectedDates===', selectedDates);
  return (
    <>
      <TopNavBar title="방막기 / 방열기" />
      {/* <RoomTypeTab roomTypes={roomTypes} roomTypeId={roomTypeId} /> */}
      <IsOpenedCalendar
        timeslots={timeslots}
        selectedDates={selectedDates}
        setSelectDate={(date: string) => setSelectDate(date)}
        setSelectedDates={(dates: string[]) => setSelectedDates(dates)}
        setOpenedRoomIds={(roomIds: number[]) =>
          setOpenedRoomIds(new Set<number>(roomIds))
        }
        setMonth={(date: Moment) => setMonth(date)}
      />
      {/* <div className="flex justify-between items-center mt-1 px-6 py-2 bg-gray-50">
        <p className="font-semibold text-xl">
          {utcToLocalFormat(selectDate || '', MomentFormat.YYYYMMDD)}
        </p>
        <p className="text-gray-600">({isOpenedTimeslot?.length})</p>
      </div> */}
      <div className="grid grid-cols-3 gap-5 p-6">
        {/* <Button
          text="전체"
          className={`outlined-brand-1 h-10 ${
            openedRoomIds.size === possibleTimeslots?.length
              ? 'text-white bg-brand-1'
              : ''
          }`}
          onClick={() => {
            if (openedRoomIds.size !== possibleTimeslots?.length) {
              setOpenedRoomIds(new Set<number>(possibleTimeslots));
              setClosedRoomIds(new Set<number>());
            } else {
              setOpenedRoomIds(new Set<number>());
              setClosedRoomIds(new Set<number>(possibleTimeslots));
            }
          }}
        /> */}
        {/* {findRoomTypes?.rooms.map((room, i) => {
          const isReservedTimeslot = timeslots?.find(
            (timeslot) =>
              moment(timeslot.checkIn).startOf('day').format() === selectDate &&
              timeslot.isReserved &&
              room.id === timeslot.room.id
          );

          return (
            <Chip
              key={i}
              text={room.title}
              className={`outlined-brand-1 h-10 ${
                (isReservedTimeslot?.room.id === room.id ||
                  openedRoomIds.has(room.id)) &&
                'text-white bg-brand-1'
              }`}
              active={
                isReservedTimeslot?.room.id === room.id ||
                openedRoomIds.has(room.id)
              }
              onClick={() => {
                if (isReservedTimeslot?.room.id === room.id) {
                  return toast.error('해당 룸은 이미 예약이 되어있습니다.');
                }
                const openRoomIds = new Set(openedRoomIds);
                const closeRoomIds = new Set(closedRoomIds);
                if (openedRoomIds.has(room.id)) {
                  openRoomIds.delete(room.id);
                  closeRoomIds.add(room.id);
                } else {
                  openRoomIds.add(room.id);
                  closeRoomIds.delete(room.id);
                }
                setOpenedRoomIds(openRoomIds);
                setClosedRoomIds(closeRoomIds);
              }}
            />
          );
        })} */}
      </div>
      <div className="flex-1" />

      <div className="p-6 sticky bottom-0">
        <Button
          text="저장하기"
          className="filled-brand-1 w-full"
          onClick={async () => {
            try {
              await adminApi
                .post(`/admin/timeslots/isOpened`, {
                  selectedDate: selectedDates,
                  roomTypeId: roomTypeId,
                })
                .then((res) => {
                  setSelectedDates([]);
                  refetch();
                });

              toast.success('방 설정이 완료되었습니다.');
            } catch (e) {
              toast.error('방 설정 오류');
            }
          }}
        />
      </div>
    </>
  );
};
