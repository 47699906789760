import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { TextField } from '../../../components/TextField';
import { adminApi } from '../../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { adminFetcher } from '../../../plugins/react-query';
import { tabSelectRoomTypeIdState } from '../../../plugins/ridge';
import { RoomType, Timeslot } from '../../../types';
import { DetailDepositCalendar } from '../../components/DetailDepositCalendar';
import { RoomTypeTab } from '../../components/RoomTypeTab';
import { TopNavBar } from '../../components/TopNavBar';

interface FormValues {
  detailPrice: number;
  selectedDate: string;
  roomTypeId: number;
}

export const DetailDepositSet = () => {
  const roomTypeId = tabSelectRoomTypeIdState.useValue();
  const today = moment();
  const [selectDate, setSelectDate] = useState<string>(
    today.startOf('day').format()
  );
  const [month, setMonth] = useState(moment());

  const from = month.startOf('month').format('YYYY-MM-DD');
  const to = month.endOf('month').format('YYYY-MM-DD');

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const { data: roomTypes } = useQuery<RoomType[]>(
    '/admin/room-types/all',
    adminFetcher
  );

  const { data: timeslots, refetch } = useQuery<Timeslot[]>(
    `/admin/timeslots/roomTypeId/${roomTypeId}?from=${from}&to=${to}`,
    adminFetcher,
    {
      enabled: !!roomTypeId,
    }
  );

  const isSameTimeslot = timeslots?.filter(
    (timeslot) =>
      moment(timeslot.checkIn).startOf('day').format() === selectDate &&
      timeslot.isOpened
  );

  useEffect(() => {
    const isSameTimeslot = timeslots?.filter(
      (timeslot) =>
        moment(timeslot.checkIn).startOf('day').format() === selectDate &&
        timeslot.isOpened
    );
    setValue(
      'detailPrice',
      (isSameTimeslot && isSameTimeslot[0]?.detailPrice) || 0
    );
  }, [roomTypes, selectDate, setValue, roomTypeId, timeslots]);

  return (
    <>
      <TopNavBar title="세부 요금 설정" />
      {/* <RoomTypeTab roomTypes={roomTypes} roomTypeId={roomTypeId} /> */}
      <DetailDepositCalendar
        timeslots={timeslots}
        setSelectDate={(date: string) => setSelectDate(date)}
        setMonth={(date: Moment) => setMonth(date)}
      />
      <div className="flex justify-between items-center mt-5 px-6 py-2 bg-gray-50">
        <p className="font-semibold text-xl">
          {utcToLocalFormat(selectDate || '', MomentFormat.YYYYMMDD)}
        </p>
        <p className="text-gray-600">
          {((isSameTimeslot && isSameTimeslot[0]?.detailPrice) || 0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          원
        </p>
      </div>
      <div className="p-6">
        <TextField
          label="세부 요금 설정"
          type="number"
          placeholder="세부금액을 입력해주세요."
          helper={errors.detailPrice?.message}
          {...register('detailPrice', {
            required: '세부금액을 입력해주세요.',
          })}
        />
      </div>
      <div className="flex-1" />
      <div className="p-6 sticky bottom-0">
        <Button
          text="저장하기"
          className="filled-brand-1 w-full"
          disabled={
            watch('detailPrice') ===
            (isSameTimeslot && isSameTimeslot[0]?.detailPrice)
          }
          onClick={handleSubmit(async (data) => {
            data.selectedDate = moment(selectDate).toISOString();
            data.roomTypeId = roomTypeId;
            try {
              await adminApi.patch(`/admin/timeslots/detail/price`, data);
              refetch();
              toast.success('세부 요금 설정이 완료되었습니다.');
            } catch (e) {
              toast.error('세부 요금 설정 오류');
            }
          })}
        />
      </div>
    </>
  );
};
