import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../assets/icons/icon-home.svg';
import { ReactComponent as GalleryIcon } from '../assets/icons/icon-gallery.svg';
import { ReactComponent as ReservationIcon } from '../assets/icons/icon-reservation.svg';
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg';
import { Button } from './Button';
import { useQuery } from 'react-query';
import { RoomType } from '../types';
import { fetcher } from '../plugins/react-query';

export const BottomNavBar = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const { data: roomType } = useQuery<RoomType>('/room-types/1', fetcher);

  const HomeActive =
    pathname === ('/home' || '/home/atin' || '/home/maru' || 'home/rent');
  const GalleryActive = pathname.startsWith('/gallery/');
  const ReservationActive = pathname === '/reservation';
  const MypageActive = pathname === '/my';

  return (
    <div className="grid grid-cols-4 pb-4 fixed max-w-md bottom-0 w-full bg-white border-t border-gray-800 pt-4 z-20">
      <div className="grid place-content-center">
        <Button
          to="/home"
          className={`px-4 ${HomeActive ? 'text-brand-1' : 'text-gray-500'}`}
        >
          <HomeIcon className="mx-auto stroke-current" />
          <span className="text-12">home</span>
        </Button>
      </div>
      <div className="grid place-content-center">
        <Button
          onClick={() =>
            push(`/gallery/lounge/${roomType?.id ?? 1}`, {
              title: roomType?.title,
            })
          }
          className={`px-4 ${GalleryActive ? 'text-brand-1' : 'text-gray-500'}`}
        >
          <GalleryIcon className="mx-auto stroke-current" />
          <span className="text-12">gallery</span>
        </Button>
      </div>

      <div className="grid place-content-center">
        <Button
          to="/reservation"
          className={`px-4 ${
            ReservationActive ? 'text-brand-1' : 'text-gray-500'
          }`}
        >
          <ReservationIcon className="mx-auto stroke-current" />
          <span className="text-12">Booking</span>
        </Button>
      </div>
      <div className="grid place-content-center">
        <Button
          to="/my"
          className={`px-4 ${MypageActive ? 'text-brand-1' : 'text-gray-500'}`}
        >
          <UserIcon className="mx-auto fill-current" />
          <span className="text-12">mypage</span>
        </Button>
      </div>
    </div>
  );
};
