import galleryMaru1 from './assets/img/maru/gallery/gallery-maru-1.jpg';
import galleryMaru2 from './assets/img/maru/gallery/gallery-maru-2.jpg';
import galleryMaru3 from './assets/img/maru/gallery/gallery-maru-3.jpg';
import galleryMaru4 from './assets/img/maru/gallery/gallery-maru-4.jpg';
import galleryMaru5 from './assets/img/maru/gallery/gallery-maru-5.jpg';
import galleryMaru6 from './assets/img/maru/gallery/gallery-maru-6.jpg';
import galleryMaru7 from './assets/img/maru/gallery/gallery-maru-7.jpg';
import galleryMaru8 from './assets/img/maru/gallery/gallery-maru-8.jpg';
import galleryMaru9 from './assets/img/maru/gallery/gallery-maru-9.jpg';
import galleryMaru10 from './assets/img/maru/gallery/gallery-maru-10.jpg';
import galleryMaru11 from './assets/img/maru/gallery/gallery-maru-11.jpg';
import galleryMaru12 from './assets/img/maru/gallery/gallery-maru-12.jpg';
import galleryMaru13 from './assets/img/maru/gallery/gallery-maru-13.jpg';
import galleryMaru14 from './assets/img/maru/gallery/gallery-maru-14.jpg';
import galleryMaru15 from './assets/img/maru/gallery/gallery-maru-15.jpg';
import galleryMaru16 from './assets/img/maru/gallery/gallery-maru-16.jpg';
import galleryMaru17 from './assets/img/maru/gallery/gallery-maru-17.jpg';
import galleryMaru18 from './assets/img/maru/gallery/gallery-maru-18.jpg';
import galleryMaru19 from './assets/img/maru/gallery/gallery-maru-19.jpg';
import galleryMaru20 from './assets/img/maru/gallery/gallery-maru-20.jpg';
import galleryMaru21 from './assets/img/maru/gallery/gallery-maru-21.jpg';
import galleryMaru22 from './assets/img/maru/gallery/gallery-maru-22.jpg';
import galleryMaru23 from './assets/img/maru/gallery/gallery-maru-23.jpg';
import galleryMaru24 from './assets/img/maru/gallery/gallery-maru-24.jpg';
import galleryMaru25 from './assets/img/maru/gallery/gallery-maru-25.jpg';
import galleryMaru26 from './assets/img/maru/gallery/gallery-maru-26.jpg';
import galleryMaru27 from './assets/img/maru/gallery/gallery-maru-27.jpg';
import galleryMaru28 from './assets/img/maru/gallery/gallery-maru-28.jpg';
import galleryMaru29 from './assets/img/maru/gallery/gallery-maru-29.jpg';
import galleryMaru30 from './assets/img/maru/gallery/gallery-maru-30.jpg';
import galleryMaru31 from './assets/img/maru/gallery/gallery-maru-31.jpg';
import galleryMaru32 from './assets/img/maru/gallery/gallery-maru-32.jpg';
import galleryMaru33 from './assets/img/maru/gallery/gallery-maru-33.jpg';
import galleryMaru34 from './assets/img/maru/gallery/gallery-maru-34.jpg';
import galleryMaru35 from './assets/img/maru/gallery/gallery-maru-35.jpg';
import galleryMaru36 from './assets/img/maru/gallery/gallery-maru-36.jpg';
import galleryMaru37 from './assets/img/maru/gallery/gallery-maru-37.jpg';
import galleryMaru38 from './assets/img/maru/gallery/gallery-maru-38.jpg';
import galleryMaru39 from './assets/img/maru/gallery/gallery-maru-39.jpg';
import galleryMaru40 from './assets/img/maru/gallery/gallery-maru-40.jpg';
import galleryMaru41 from './assets/img/maru/gallery/gallery-maru-41.jpg';
import galleryMaru42 from './assets/img/maru/gallery/gallery-maru-42.jpg';
import galleryMaru43 from './assets/img/maru/gallery/gallery-maru-43.jpg';

import villaNuteThumbnail0 from './assets/img/villa-nute/villa-nute-thumbnail-0.jpg';
import villaNuteRoom0 from './assets/img/villa-nute/villa-nute-room-0.jpg';

import spring0 from './assets/img/maru/room/spring0.png';
import spring1 from './assets/img/maru/room/spring1.jpg';
import spring2 from './assets/img/maru/room/spring2.jpg';
import summer1 from './assets/img/maru/room/summer1.jpg';
import summer2 from './assets/img/maru/room/summer2.jpg';
import autumn1 from './assets/img/maru/room/autumn1.jpg';
import autumn2 from './assets/img/maru/room/autumn2.jpg';
import winter1 from './assets/img/maru/room/winter1.jpg';
import winter2 from './assets/img/maru/room/winter2.jpg';
import public1 from './assets/img/maru/room/public1.jpg';
import public2 from './assets/img/maru/room/public2.jpg';
import public3 from './assets/img/maru/room/public3.jpg';
import public4 from './assets/img/maru/room/public4.jpg';
import public5 from './assets/img/maru/room/public5.jpg';
import public6 from './assets/img/maru/room/public6.jpg';
import public7 from './assets/img/maru/room/public7.jpg';
import public8 from './assets/img/maru/room/public8.jpg';
import public9 from './assets/img/maru/room/public9.jpg';
import public10 from './assets/img/maru/room/public10.jpg';
import public11 from './assets/img/maru/room/public11.jpg';

import galleryHaue1 from './assets/img/haue/gallery/gallery-haue-1.png';
import galleryHaue2 from './assets/img/haue/gallery/gallery-haue-2.png';
import galleryHaue3 from './assets/img/haue/gallery/gallery-haue-3.png';
import galleryHaue4 from './assets/img/haue/gallery/gallery-haue-4.png';
import galleryHaue5 from './assets/img/haue/gallery/gallery-haue-5.png';
import galleryHaue6 from './assets/img/haue/gallery/gallery-haue-6.png';
import galleryHaue7 from './assets/img/haue/gallery/gallery-haue-7.png';
import galleryHaue8 from './assets/img/haue/gallery/gallery-haue-8.png';
import galleryHaue9 from './assets/img/haue/gallery/gallery-haue-9.png';
import galleryHaue10 from './assets/img/haue/gallery/gallery-haue-10.png';
import galleryHaue11 from './assets/img/haue/gallery/gallery-haue-11.png';
import galleryHaue12 from './assets/img/haue/gallery/gallery-haue-12.png';
import galleryHaue13 from './assets/img/haue/gallery/galleryHaue13.jpg';
import galleryHaue14 from './assets/img/haue/gallery/galleryHaue14.jpg';
import galleryHaue15 from './assets/img/haue/gallery/galleryHaue15.jpg';
import galleryHaue16 from './assets/img/haue/gallery/galleryHaue16.jpg';
import galleryHaue17 from './assets/img/haue/gallery/galleryHaue17.jpg';
import galleryHaue18 from './assets/img/haue/gallery/galleryHaue18.jpg';
import galleryHaue19 from './assets/img/haue/gallery/galleryHaue19.jpg';
import galleryHaue20 from './assets/img/haue/gallery/galleryHaue20.jpg';
import galleryHaue21 from './assets/img/haue/gallery/galleryHaue21.jpg';
import galleryHaue22 from './assets/img/haue/gallery/galleryHaue22.jpg';
import galleryHaue23 from './assets/img/haue/gallery/galleryHaue23.jpg';
import galleryHaue24 from './assets/img/haue/gallery/gallery-haue-24.jpg';
import galleryHaue25 from './assets/img/haue/gallery/gallery-haue-25.jpg';
import galleryHaue26 from './assets/img/haue/gallery/gallery-haue-26.jpg';
import galleryHaue27 from './assets/img/haue/gallery/gallery-haue-27.jpg';
import galleryHaue28 from './assets/img/haue/gallery/gallery-haue-28.jpg';
import galleryHaue29 from './assets/img/haue/gallery/gallery-haue-29.jpg';
import galleryHaue30 from './assets/img/haue/gallery/gallery-haue-30.jpg';
import galleryHaue31 from './assets/img/haue/gallery/gallery-haue-31.jpg';
import galleryHaue32 from './assets/img/haue/gallery/gallery-haue-32.jpg';
import galleryHaue33 from './assets/img/haue/gallery/gallery-haue-33.jpg';
import galleryHaue34 from './assets/img/haue/gallery/gallery-haue-34.jpg';
import galleryHaue35 from './assets/img/haue/gallery/gallery-haue-35.jpg';
import galleryHaue36 from './assets/img/haue/gallery/gallery-haue-36.jpg';
import galleryHaue37 from './assets/img/haue/gallery/gallery-haue-37.jpg';
import galleryHaue38 from './assets/img/haue/gallery/gallery-haue-38.jpg';
import galleryHaue39 from './assets/img/haue/gallery/gallery-haue-39.jpg';
import galleryHaue40 from './assets/img/haue/gallery/gallery-haue-40.jpg';
import galleryHaue41 from './assets/img/haue/gallery/gallery-haue-41.jpg';
import galleryHaue42 from './assets/img/haue/gallery/gallery-haue-42.jpg';

import haue1 from './assets/img/haue/room/haue-room-1.png';
import haue2 from './assets/img/haue/room/haue-room-2.png';
import { PageTitle } from './types';

export const maruGalleries = [
  { id: 1, image: galleryMaru1 },
  { id: 2, image: galleryMaru2 },
  { id: 3, image: galleryMaru3 },
  { id: 4, image: galleryMaru4 },
  { id: 5, image: galleryMaru5 },
  { id: 6, image: galleryMaru6 },
  { id: 7, image: galleryMaru7 },
  { id: 8, image: galleryMaru8 },
  { id: 9, image: galleryMaru9 },
  { id: 10, image: galleryMaru10 },
  { id: 11, image: galleryMaru11 },
  { id: 12, image: galleryMaru12 },
  { id: 13, image: galleryMaru13 },
  { id: 14, image: galleryMaru14 },
  { id: 15, image: galleryMaru15 },
  { id: 16, image: galleryMaru16 },
  { id: 17, image: galleryMaru17 },
  { id: 18, image: galleryMaru18 },
  { id: 19, image: galleryMaru19 },
  { id: 20, image: galleryMaru20 },
  { id: 21, image: galleryMaru21 },
  { id: 22, image: galleryMaru22 },
  { id: 23, image: galleryMaru23 },
  { id: 24, image: galleryMaru24 },
  { id: 25, image: galleryMaru25 },
  { id: 26, image: galleryMaru26 },
  { id: 27, image: galleryMaru27 },
  { id: 28, image: galleryMaru28 },
  { id: 29, image: galleryMaru29 },
  { id: 30, image: galleryMaru30 },
  { id: 31, image: galleryMaru31 },
  { id: 32, image: galleryMaru32 },
  { id: 33, image: galleryMaru33 },
  { id: 34, image: galleryMaru34 },
  { id: 35, image: galleryMaru35 },
  { id: 36, image: galleryMaru36 },
  { id: 37, image: galleryMaru37 },
  { id: 38, image: galleryMaru38 },
  { id: 39, image: galleryMaru39 },
  { id: 40, image: galleryMaru40 },
  { id: 41, image: galleryMaru41 },
  { id: 42, image: galleryMaru42 },
  { id: 43, image: galleryMaru43 },
];

export const maruRoomThumbnails = [
  { id: 1, roomId: 1, image: spring1 },
  { id: 2, roomId: 2, image: summer1 },
  { id: 3, roomId: 3, image: autumn1 },
  { id: 4, roomId: 4, image: winter1 },
];

export const villaNuteThumbnail = [
  { id: 1, roomId: 1, image: villaNuteThumbnail0 },
];

export const villaNuteDetailImages = [
  { id: 1, roomId: 1, image: villaNuteRoom0 },
];

export const maruRoomDetailImages = [
  { id: 1, roomId: 1, image: spring0 },
  { id: 2, roomId: 1, image: spring1 },
  { id: 3, roomId: 1, image: spring2 },
  { id: 4, roomId: 2, image: summer1 },
  { id: 5, roomId: 2, image: summer2 },
  { id: 6, roomId: 3, image: autumn1 },
  { id: 7, roomId: 3, image: autumn2 },
  { id: 8, roomId: 4, image: winter1 },
  { id: 9, roomId: 4, image: winter2 },
  { id: 10, roomId: 0, image: public1 },
  { id: 11, roomId: 0, image: public2 },
  { id: 12, roomId: 0, image: public3 },
  { id: 13, roomId: 0, image: public4 },
  { id: 14, roomId: 0, image: public5 },
  { id: 15, roomId: 0, image: public6 },
  { id: 16, roomId: 0, image: public7 },
  { id: 17, roomId: 0, image: public8 },
  { id: 18, roomId: 0, image: public9 },
  { id: 19, roomId: 0, image: public10 },
  { id: 20, roomId: 0, image: public11 },
];

export const haueGalleries = [
  { id: 1, image: galleryHaue1 },
  { id: 2, image: galleryHaue2 },
  { id: 3, image: galleryHaue3 },
  { id: 4, image: galleryHaue4 },
  { id: 5, image: galleryHaue5 },
  { id: 6, image: galleryHaue6 },
  { id: 7, image: galleryHaue7 },
  { id: 8, image: galleryHaue8 },
  { id: 9, image: galleryHaue9 },
  { id: 10, image: galleryHaue10 },
  { id: 11, image: galleryHaue11 },
  { id: 12, image: galleryHaue12 },
  { id: 13, image: galleryHaue13 },
  { id: 14, image: galleryHaue14 },
  { id: 15, image: galleryHaue15 },
  { id: 16, image: galleryHaue16 },
  { id: 17, image: galleryHaue17 },
  { id: 18, image: galleryHaue18 },
  { id: 19, image: galleryHaue19 },
  { id: 20, image: galleryHaue20 },
  { id: 21, image: galleryHaue21 },
  { id: 22, image: galleryHaue22 },
  { id: 23, image: galleryHaue23 },
  { id: 24, image: galleryHaue24 },
  { id: 25, image: galleryHaue25 },
  { id: 26, image: galleryHaue26 },
  { id: 27, image: galleryHaue27 },
  { id: 28, image: galleryHaue28 },
  { id: 29, image: galleryHaue29 },
  { id: 30, image: galleryHaue30 },
  { id: 31, image: galleryHaue31 },
  { id: 32, image: galleryHaue32 },
  { id: 33, image: galleryHaue33 },
  { id: 34, image: galleryHaue34 },
  { id: 35, image: galleryHaue35 },
  { id: 36, image: galleryHaue36 },
  { id: 37, image: galleryHaue37 },
  { id: 38, image: galleryHaue38 },
  { id: 39, image: galleryHaue39 },
  { id: 40, image: galleryHaue40 },
  { id: 41, image: galleryHaue41 },
  { id: 42, image: galleryHaue42 },
];

export const haueRoomThumbnails = [
  { id: 1, roomId: 5, image: haue1 },
  { id: 2, roomId: 6, image: haue2 },
  { id: 3, roomId: 0, image: galleryHaue1 },
  { id: 4, roomId: 0, image: galleryHaue2 },
  { id: 5, roomId: 0, image: galleryHaue3 },
  { id: 6, roomId: 0, image: galleryHaue4 },
  { id: 7, roomId: 0, image: galleryHaue5 },
  { id: 8, roomId: 0, image: galleryHaue6 },
  { id: 9, roomId: 0, image: galleryHaue7 },
  { id: 10, roomId: 0, image: galleryHaue8 },
  { id: 11, roomId: 0, image: galleryHaue9 },
  { id: 12, roomId: 0, image: galleryHaue10 },
  { id: 13, roomId: 0, image: galleryHaue11 },
  { id: 14, roomId: 0, image: galleryHaue12 },
  { id: 15, roomId: 5, image: galleryHaue13 },
  { id: 16, roomId: 0, image: galleryHaue14 },
  { id: 17, roomId: 0, image: galleryHaue15 },
  { id: 18, roomId: 0, image: galleryHaue16 },
  { id: 19, roomId: 0, image: galleryHaue17 },
  { id: 20, roomId: 0, image: galleryHaue18 },
  { id: 21, roomId: 5, image: galleryHaue19 },
  { id: 22, roomId: 0, image: galleryHaue20 },
  { id: 23, roomId: 6, image: galleryHaue21 },
  { id: 24, roomId: 0, image: galleryHaue22 },
  { id: 25, roomId: 0, image: galleryHaue23 },
  { id: 26, roomId: 0, image: galleryHaue24 },
  { id: 27, roomId: 0, image: galleryHaue25 },
  { id: 28, roomId: 0, image: galleryHaue26 },
  { id: 29, roomId: 0, image: galleryHaue27 },
  { id: 30, roomId: 0, image: galleryHaue28 },
  { id: 31, roomId: 0, image: galleryHaue29 },
  { id: 32, roomId: 0, image: galleryHaue30 },
  { id: 33, roomId: 0, image: galleryHaue31 },
  { id: 34, roomId: 0, image: galleryHaue32 },
  { id: 35, roomId: 0, image: galleryHaue33 },
  { id: 36, roomId: 0, image: galleryHaue34 },
  { id: 37, roomId: 0, image: galleryHaue35 },
  { id: 38, roomId: 0, image: galleryHaue36 },
  { id: 39, roomId: 0, image: galleryHaue37 },
  { id: 40, roomId: 0, image: galleryHaue38 },
  { id: 41, roomId: 0, image: galleryHaue39 },
  { id: 42, roomId: 0, image: galleryHaue40 },
  { id: 43, roomId: 0, image: galleryHaue41 },
  { id: 44, roomId: 0, image: galleryHaue42 },
];

export const pageTitles = [
  {
    id: 1,
    title: PageTitle.STORY,
  },
  {
    id: 2,
    title: PageTitle.GUIDE,
  },
  {
    id: 3,
    title: PageTitle.RENT,
  },
  {
    id: 4,
    title: PageTitle.CONTACT,
  },
];
