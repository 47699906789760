import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { adminFetcher } from '../../../plugins/react-query';
import { tabSelectRoomTypeIdState } from '../../../plugins/ridge';
import { RoomOption, RoomType } from '../../../types';
import { RoomTypeTab } from '../../components/RoomTypeTab';
import { TopNavBar } from '../../components/TopNavBar';

export const OptionSet = () => {
  const roomTypeId = tabSelectRoomTypeIdState.useValue();
  const { push } = useHistory();

  const { data: roomTypes } = useQuery<RoomType[]>(
    '/admin/room-types/all',
    adminFetcher
  );

  const { data: roomOptions } = useQuery<RoomOption[]>(
    `/admin/room-options/roomType/${roomTypeId}`,
    adminFetcher,
    { enabled: !!roomTypeId }
  );

  return (
    <>
      <TopNavBar title="결제시 추가 옵션 설정" />
      {/* <RoomTypeTab roomTypes={roomTypes} roomTypeId={roomTypeId} /> */}
      <div className="px-6 pb-6 pt-3 ">
        {roomOptions?.map((roomOption) => (
          <div
            className="py-3 border-b cursor-pointer font-medium"
            onClick={() => push(`/admin/rooms/option/${roomOption.id}`)}
          >
            {roomOption.title}
          </div>
        ))}
        <Button
          text="추가하기"
          className="outlined-brand-1 w-full mt-5"
          onClick={() =>
            push(`/admin/rooms/option/add?roomTypeId=${roomTypeId}`)
          }
        />
      </div>
    </>
  );
};
