export interface Paginated<T> {
  items: T[];
  total: number;
}

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface User {
  id: number;
  createdAt: string;
  updatedAt: string;
  role: Role;
  name: string;
  email: string;
  phoneNumber: string;
  termsOfService: boolean;
  privacyPolicy: boolean;
  thirdPartyConsent: boolean;
  marketingTerms: boolean;
  totalPoint: number;
  resetToken: string;
  resetTokenExpiredAt: string;
  memo: string;
  isDeleted: boolean;
  deletedAt: string;
}

export interface MobileVerification {
  id: number;
  createdAt: string;
  updatedAt: string;
  number: string;
  code: string;
  expiredAt: Date;
  verified: boolean;
}

export interface OrderRoomOption {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  description: string;
  price: number;
  quantity: number;
  paymentOrderId: number;
  roomOptionId: number;
}

export enum PaymentOrderState {
  READY = 'READY', // 결제 대기
  PAID = 'PAID', // 결제 완료
  CANCELED = 'CANCELED', // 취소
  REFUND = 'REFUND', // 환불
}

export enum PaymentMethod {
  CARD = 'CARD', // 카드
  BANK = 'BANK', // 무통장 입금
  N_PAY = 'N_PAY', // 네이버페이
  K_PAY = 'K_PAY', // 카카오페이
}

export enum CashReceiptUserType {
  INDIVIDUAL = 'INDIVIDUAL', // 개인
  ENTREPRENEUR = 'ENTREPRENEUR', // 사업자
}

export interface PaymentOrder {
  id: number;
  createdAt: string;
  updatedAt: string;
  state: PaymentOrderState;
  paymentMethod: PaymentMethod;
  totalPrice: number;
  finalPrice: number;
  roomPrice: number;
  additionalPersonAmount?: number;
  cancelledPrice?: number;
  remainPrice?: number;
  receiptId: string;
  orderId: string;
  adult: number;
  child: number;
  infant: number;
  refundBank: string;
  depositor: string;
  refundAccount: string;
  usedPoint: number;
  depositedPoint: number;
  returnedPoint: number;
  onePersonAmount: number;
  classAmount: number;
  cashReceiptUserType: CashReceiptUserType;
  cashReceiptName: string;
  cashReceiptNumber: string;
  paidAt: string;
  cancelRequestedAt: string;
  canceledAt: string;
  bank: string;
  cardNo: string;
  voucherName: string;
  voucherNameEmail: string;
  voucherPhoneNumber: string;
  reservationName: string;
  reservationPhoneNumber: string;
  reservationEmail: string;
  checkIn: string;
  checkOut: string;
  roomTypeTitle: string;
  roomTitle: string;
  requestedTerm: string;
  minorsNotAllowedTerm: boolean;
  groupLimitEnabled: boolean;
  lateCheckoutNotice: boolean;
  finalApproval: boolean;
  noSmoking: boolean;
  compensationRegulations: boolean;
  inflowOfInsects: boolean;
  unCookable: boolean;
  serviceUseTerms: boolean;
  informationCollectionTerms: boolean;
  refundRules: boolean;
  marketingTerms: boolean;
  memo: string;
  userId: number;
  orderRoomOptions: OrderRoomOption[];
}

export enum PointHistoryType {
  PROVISION = 'PROVISION', // 지급
  DEDUCTIBLE = 'DEDUCTIBLE', // 차감
}

export interface PointHistory {
  id: number;
  createdAt: string;
  updatedAt: string;
  pointType: PointHistoryType;
  title: string;
  value: number;
  memo: string;
  paymentId: number;
  userId: number;
  user: User;
}

export enum ReservationState {
  BEFORE_DEPOSIT = 'BEFORE_DEPOSIT', // 입금 전
  APPLICATION_FOR_RESERVATION = 'APPLICATION_FOR_RESERVATION', // 예약 신청
  RESERVATION_CONFIRMATION = 'RESERVATION_CONFIRMATION', // 예약 확정
  CANCELLATION_REQUEST = 'CANCELLATION_REQUEST', // 취소 요청
  CANCELED = 'CANCELED', // 취소 완료
  USED = 'USED', // 이용 완료
}

export interface Reservation {
  id: number;
  createdAt: string;
  updatedAt: string;
  state: ReservationState;
  checkIn: string;
  checkOut: string;
  adult: number;
  child: number;
  infant: number;
  requestedTerm: string;
  minorsNotAllowedTerm: boolean;
  noSmoking: boolean;
  compensationRegulations: boolean;
  inflowOfInsects: boolean;
  unCookable: boolean;
  serviceUseTerms: boolean;
  informationCollectionTerms: boolean;
  refundRules: boolean;
  marketingTerms: boolean;
  usedPoint: number;
  depositedPoint: number;
  memo: string;
  reservationName: string;
  reservationPhoneNumber: string;
  reservationEmail: string;
  roomTypeTitle: string;
  roomTitle: string;
  weekdayPrice: number;
  fridayPrice: number;
  saturdayPrice: number;
  sundayPrice: number;
  adultAdditionalPrice: number;
  childrenAdditionalPrice: number;
  infantAdditionalPrice: number;
  consecutiveNightCount: number;
  discountRate: number;
  paidAt: string;
  cancelRequestedAt: string;
  canceledAt: string;
  userId: number;
  roomId: number;
  roomTypeId: number;
  paymentOrderId: number;
  paymentOrder: PaymentOrder;
}

export interface Room {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  accommodationInfo: string;
  reservationRoomInfo: string;
  memo: string;
  isDisabled: boolean;
  roomTypeId: number;
  roomType: RoomType;
  timeslots: Timeslot[];
}

export interface RoomOption {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  description: string;
  price: number;
  roomTypeId: number;
  roomId: number;
}

export interface RoomType {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  weekdayPrice: number;
  fridayPrice: number;
  saturdayPrice: number;
  sundayPrice: number;
  adultAdditionalPrice: number;
  childrenAdditionalPrice: number;
  infantAdditionalPrice: number;
  consecutiveNights: number;
  discountRate: number;
  moreThanTwoNights: boolean;
  memo: string;
  content: string;
  rooms: Room[];
}

export interface Timeslot {
  id: number;
  createdAt: string;
  updatedAt: string;
  checkIn: string;
  checkOut: string;
  detailPrice: number;
  isOpened: boolean;
  isReserved: boolean;
  reservationId: number;
  roomTypeId: number;
  roomId: number;
  roomType: RoomType;
  room: Room;
  reservation?: Reservation;
}

export enum PageTitle {
  STORY = 'STORY', //메인 홈페이지
  GUIDE = 'GUIDE',
  RENT = 'RENT',
  CONTACT = 'CONTACT',
  POLICY = 'POLICY',
}

export interface Description {
  id: number;
  createdAt: string;
  updatedAt: string;
  content: string;
  imageFile: string;
  title: PageTitle;
}

export interface Gallery {
  id: number;
  createdAt: string;
  updatedAt: string;
  imageFile: string;
  roomId?: number;
  roomTypeId: number;
  sorted: any;
}
