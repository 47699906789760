import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { AuthRoute } from '../components/AuthRoute';
import { LoginPage } from './loginSignup/LoginPage';
import { LandingPage } from './LandingPage';
import { HomePage } from './HomePage';
import { SignupPage } from './loginSignup/SignupPage';
import { SignupSuccessPage } from './loginSignup/SignupSuccessPage';
import { FindPasswordResetPage } from './loginSignup/FindPasswordResetPage';
import { FindEmailSuccessPage } from './loginSignup/FindEmailSuccessPage';
import { AtinMain } from './home/AtinMain';
import { MaruMain } from './home/MaruMain';
import { HaeuMain } from './home/HaeuMain';
import { RentMain } from './home/RentMain';
import { ShopMain } from './shop/ShopMain';
import { ContactMain } from './home/ContactMain';
import { Gallery } from './gallery/GalleryPage';
import { Lounge } from './gallery/LoungePage';
import { Cabins } from './gallery/CabinsPage';
import { Environment } from './gallery/EnvironmentPage';
import { BottomNavBar } from '../components/BottomNavBar';
import { FindEmailPage } from './loginSignup/FindEmailPage';
import { FindPasswordPage } from './loginSignup/FindPasswordPage';
import { OrderPage } from './reservation/OrderPage';
import { OrderSuccess } from './reservation/OrderSuccess';
import { MyReservation } from './my/MyReservation';
import { MyPage } from './my/MyPage';
import { MyReservationDetail } from './my/MyReservationDetail';
import { MyCancelReservationDetail } from './my/MyCancelReservationDetail';
import { MyEditPage } from './my/MyEditPage';
import { MyPointsPage } from './my/MyPointsPage';
import { ReservationCalendarPage } from './reservation/ReservationCalendarPage';
import { VoucherPage } from './shop/VoucherPage';
import { RoomDetailPage } from './reservation/RoomDetailPage';
import { ReservationRoomsPage } from './reservation/ReservationRoomsPage';
import { ReservationRoomTypePage } from './reservation/ReservationRoomTypePage';
import { GalleryRoomTypePage } from './gallery/GalleryRoomTypePage';

export const Router = () => {
  const { pathname } = useLocation();
  const isShowNavBar =
    pathname.startsWith('/home') ||
    pathname === '/home/maru' ||
    pathname.startsWith('/gallery/') ||
    pathname === '/my' ||
    pathname === '/shop' ||
    pathname === '/reservation';

  return (
    <div className="min-h-screen max-w-md mx-auto flex flex-col">
      <main className="relative flex-1 flex flex-col h-full">
        {/* {아틴/마루/해움/SHOP은 퍼블리싱 완료 후  비회원 접근불가} */}
        <Switch>
          <Route path="/home/rent" component={RentMain} />
          <AuthRoute path="/shop/voucher" component={VoucherPage} />
          <AuthRoute path="/shop" component={ShopMain} />
          <Route path="/contact" component={ContactMain} />
          <Route path="/gallery/lounge/:id" component={Lounge} />
          <Route path="/gallery/cabins/:id" component={Cabins} />
          <Route path="/gallery/environment/:id" component={Environment} />
          <Route path="/gallery/room-types" component={GalleryRoomTypePage} />
          <Route path="/gallery/:id" component={Gallery} />

          <AuthRoute path="/order/success" component={OrderSuccess} />
          <AuthRoute path="/order/:id" component={OrderPage} />

          <Route
            path="/reservation/calendar"
            component={ReservationCalendarPage}
          />
          <AuthRoute path="/reservation/room/:id" component={RoomDetailPage} />
          <AuthRoute path="/reservation/:id" component={ReservationRoomsPage} />
          <Route path="/reservation" component={ReservationRoomTypePage} />

          <AuthRoute
            path="/my/reservation/cancel/:id"
            component={MyCancelReservationDetail}
          />
          <AuthRoute path="/my/point" component={MyPointsPage} />
          <AuthRoute path="/my/edit" component={MyEditPage} />
          <AuthRoute
            path="/my/reservation/:id"
            component={MyReservationDetail}
          />
          <AuthRoute path="/my/reservations" component={MyReservation} />
          <AuthRoute path="/my" component={MyPage} />
          <AuthRoute
            path="/find/email/success"
            component={FindEmailSuccessPage}
            guestOnly
          />
          <AuthRoute path="/find/email" component={FindEmailPage} guestOnly />
          <AuthRoute
            path="/find/password/reset"
            component={FindPasswordResetPage}
            guestOnly
          />
          <AuthRoute
            path="/find/password"
            component={FindPasswordPage}
            guestOnly
          />
          <Route path="/home/guide" component={HaeuMain} />
          <Route path="/home/story" component={MaruMain} />
          <Route path="/home/atin" component={AtinMain} />
          <Route path="/signup/success" component={SignupSuccessPage} />
          <AuthRoute path="/signup" component={SignupPage} guestOnly />
          <AuthRoute path="/login" component={LoginPage} guestOnly />
          <Route path="/home" component={HomePage} exact />
          <Route path="/landing" component={LandingPage} exact />
          <Redirect from="/" to="/home" />
        </Switch>
      </main>
      <div className={isShowNavBar ? 'mt-20' : ''}>
        {isShowNavBar && <BottomNavBar />}
      </div>
    </div>
  );
};
