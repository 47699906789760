import React, { useEffect, useState } from 'react';
import { MainCard } from '../components/MainCard';
import { FooterBar } from '../components/FooterBar';
import { useAuth } from '../hooks';
import { useHistory } from 'react-router-dom';
import { DefaultModal } from '../components/modal/DefaultModal';
import { useQuery } from 'react-query';
import { Description, PageTitle } from '../types';
import { fetcher } from '../plugins/react-query';

export const HomePage = () => {
  const { push } = useHistory();
  const { authenticated } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToLogin = (link: string) => {
    (!authenticated && link === '/shop') || link === '/shop'
      ? setIsOpen(true)
      : push(link);
  };
  const { data: description, refetch } = useQuery<Description[]>(
    `descriptions/all`,
    fetcher
  );
  const isKakaoInAppBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /KAKAOTALK/i.test(userAgent);
  };

  useEffect(() => {
    const teset = isKakaoInAppBrowser();
  }, []);

  return (
    <>
      <DefaultModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="준비중입니다."
        description="곧 오픈될 예정이니, 조금만 기다려주세요!"
      />

      <MainCard
        src={
          description?.length !== 0
            ? description?.filter((item) => item.title === PageTitle.STORY)[0]
                .imageFile ?? ''
            : ''
        }
        onClick={() => handleToLogin('/home/story')}
      />
      <MainCard
        src={
          description?.length !== 0
            ? description?.filter((item) => item.title === PageTitle.GUIDE)[0]
                .imageFile ?? ''
            : ''
        }
        onClick={() => handleToLogin('/home/guide')}
      />
      <MainCard
        src={
          description?.length !== 0
            ? description?.filter((item) => item.title === PageTitle.RENT)[0]
                .imageFile ?? ''
            : ''
        }
        link="/home/rent"
      />

      <MainCard
        src={
          description?.length !== 0
            ? description?.filter((item) => item.title === PageTitle.CONTACT)[0]
                .imageFile ?? ''
            : ''
        }
        link="/contact"
      />
      <FooterBar />
    </>
  );
};
