import React, { useState } from 'react';
import { TopBar } from '../../components/TopBar';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { Button } from '../../components/Button';
import { DefaultModal } from '../../components/modal/DefaultModal';
import { CashReceiptModal } from '../../components/modal/CashReceiptModal';
import { useParams } from 'react-router-dom';
import {
  Description,
  PageTitle,
  PaymentMethod,
  Reservation,
  ReservationState,
} from '../../types';
import { useQuery } from 'react-query';
import { fetcher } from '../../plugins/react-query';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import moment from 'moment';
import { api } from '../../plugins/axios';
import { toast } from 'react-toastify';
import { villaNuteDetailImages } from '../../dummies';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { tabSelectPageTitleState } from '../../plugins/ridge';

export const MyReservationDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenReceipt, setIsOpenReceipt] = useState<boolean>(false);

  tabSelectPageTitleState.set(PageTitle.POLICY);
  const pageTitle = tabSelectPageTitleState.useValue();
  const { data: description } = useQuery<Description>(
    `/descriptions?title=${pageTitle}`,
    fetcher
  );
  const { data: reservation, refetch } = useQuery<Reservation>(
    `/reservations/${id}`,
    fetcher,
    {
      enabled: !!id,
    }
  );

  // 사용자가 선택한 날짜 연박일 수 계산
  const countingNights =
    reservation?.checkIn &&
    reservation?.checkOut &&
    moment(reservation?.checkOut).diff(moment(reservation?.checkIn), 'days');

  // 옵션 상품 금액
  const roomOptionPrice = reservation?.paymentOrder?.orderRoomOptions?.map(
    (orderRoomOption) => {
      return Number(orderRoomOption.quantity) * orderRoomOption.price;
    }
  );

  // 선택한 옵션 총 금액
  const roomOptionTotalPrice = roomOptionPrice?.reduce((stack, el) => {
    return stack + el;
  }, 0);

  // 당일 취소 요청 불가
  const today = moment()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format();
  const reservationCheckIn = moment(reservation?.checkIn || '')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format();
  const isImpossibilityReservation = today === reservationCheckIn;

  // 숙소 썸네일
  const roomThumbnail = villaNuteDetailImages.filter(
    (image) => image.roomId === 1
  );

  if (!reservation) {
    return <></>;
  }
  return (
    <>
      <DefaultModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="예약취소를 요청할까요?"
        buttonText="예약취소 요청하기"
        onClick={async () =>
          await api
            .patch<Reservation>(`/reservations/cancellation-request/${id}`)
            .then(() => {
              setIsOpen(false);
              refetch();
              toast.success('예약취소 요청이 완료되었습니다.');
            })
            .catch(() => {
              toast.error('예약취소 요청이 실패했습니다.');
            })
        }
        description={
          <>
            <p>
              취소 요청시 관리자가 확인 후 취소될 예정입니다. 환불금액은
              빌라누트 환불정책에 따라 이루어질 예정입니다.
            </p>
            <br />
            <span className="text-red-500">
              이벤트 예약이 아닌 경우 양도 가능
            </span>
          </>
        }
      />
      <CashReceiptModal
        open={isOpenReceipt}
        paymentOrder={reservation.paymentOrder}
        onClose={() => {
          setIsOpenReceipt(false);
          refetch();
        }}
      />
      <TopBar text="예약조회" backButton />
      <div>
        <Swiper
          spaceBetween={0}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {roomThumbnail?.map((img, index) => (
            <SwiperSlide className="aspect-[5/3] relative" key={index}>
              <img
                src={img.image}
                alt=""
                className="w-full h-full object-cover absolute"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex items-center py-5">
          <div className="flex-1 text-center font-semibold space-y-1">
            <p className="text-sm text-gray-500 font-normal">체크인</p>
            <div>
              <p>{utcToLocalFormat(reservation.checkIn, MomentFormat.LLdd)}</p>
              <p>오후 16:00</p>
            </div>
          </div>
          <div className="h-16 border-r" />
          <div className="flex-1 text-center font-semibold space-y-1">
            <p className="text-sm text-gray-500 font-normal">체크아웃</p>
            <div>
              <p>{utcToLocalFormat(reservation.checkOut, MomentFormat.LLdd)}</p>
              <p>오전 11:00</p>
            </div>
          </div>
        </div>

        <div className="h-3 bg-gray-50" />

        <div className="p-6">
          <div className="flex justify-between border-b">
            <h3 className="text-xl font-semibold mb-3">예약 숙소 정보</h3>
            <p
              className={`${
                reservation.state === ReservationState.CANCELED
                  ? 'text-gray-600'
                  : reservation.state === ReservationState.CANCELLATION_REQUEST
                  ? 'text-red-600'
                  : reservation.state ===
                    ReservationState.APPLICATION_FOR_RESERVATION
                  ? 'text-blue-600'
                  : reservation.state ===
                    ReservationState.RESERVATION_CONFIRMATION
                  ? 'text-gray-600'
                  : 'text-gray-600'
              }`}
            >
              {reservation.state === ReservationState.CANCELED
                ? '취소완료'
                : reservation.state === ReservationState.CANCELLATION_REQUEST
                ? '취소신청'
                : reservation.state ===
                  ReservationState.APPLICATION_FOR_RESERVATION
                ? '예약신청'
                : reservation.state ===
                  ReservationState.RESERVATION_CONFIRMATION
                ? '예약완료'
                : ''}
            </p>
          </div>
          <div className="space-y-2 pt-3 ">
            <div className="flex justify-between items-center text-lg font-medium">
              <div className="">숙소명</div>
              <div className="">{reservation.roomTypeTitle}</div>
            </div>
            {[
              {
                label: '객실명',
                content: reservation.roomTitle,
              },
              {
                label: '예약 기간',
                content: `${utcToLocalFormat(
                  reservation.checkIn,
                  MomentFormat.YMDdd
                )} - ${utcToLocalFormat(
                  reservation.checkOut,
                  MomentFormat.YMDdd
                )} ${countingNights}박${Number(countingNights) + 1}일`,
              },
              {
                label: '예약자명',
                content: reservation.reservationName,
              },
              {
                label: '예약자 핸드폰 번호',
                content: reservation.reservationPhoneNumber,
              },
              {
                label: '인원수',
                content: `성인 ${reservation.adult}명 / 아동 ${
                  reservation.child ? reservation.child : 0
                }명 / 영아 ${reservation.infant ? reservation.infant : 0}명`,
              },
              {
                label: '추가사항',
                content: reservation.paymentOrder.orderRoomOptions.map(
                  (orderRoomOption) => (
                    <>
                      {orderRoomOption.title}({orderRoomOption.quantity}){' '}
                    </>
                  )
                ),
              },
              {
                label: '요청사항',
                content: reservation.requestedTerm || '-',
              },
            ].map((e, index) => (
              <div
                className="flex justify-between items-center text-sm"
                key={index}
              >
                <div className="text-gray-600 whitespace-pre">{e.label}</div>
                <div
                  className="text-gray-900 text-13 text-right"
                  style={{ wordBreak: 'keep-all' }}
                >
                  {e.content}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="h-3 bg-gray-50" />

        <div className="p-6">
          <h3 className="text-xl font-semibold pb-3 border-b mb-3">
            결제 정보
          </h3>
          <div className="space-y-2">
            <div className="flex justify-between items-center text-lg font-medium">
              <div className="text-gray-600 font-normal text-sm">
                총 결제 금액
              </div>
              <div className="">
                {reservation.paymentOrder.finalPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
              </div>
            </div>
            {[
              {
                label: '객실 요금',
                content:
                  (reservation.paymentOrder.roomPrice
                    ? reservation.paymentOrder.roomPrice
                    : reservation.paymentOrder.totalPrice
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
              },
              {
                label: '추가 인원 요금',
                content:
                  (reservation.paymentOrder.additionalPersonAmount || 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
              },
              {
                label: '추가옵션',
                content:
                  (roomOptionTotalPrice || 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
              },
              {
                label: '적립금 사용 금액',
                content:
                  (reservation.usedPoint || 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'P',
              },
              {
                label: '적립금 적립 예정',
                content:
                  reservation.depositedPoint
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'P',
              },
              {
                label: '결제일시',
                content: utcToLocalFormat(
                  reservation.paymentOrder.paidAt,
                  MomentFormat.YYYYMMDDHmm
                ),
              },
              {
                label: '결제방법',
                content:
                  reservation.paymentOrder.paymentMethod === PaymentMethod.CARD
                    ? '카드결제'
                    : reservation.paymentOrder.paymentMethod ===
                      PaymentMethod.BANK
                    ? '무통장 입금'
                    : reservation.paymentOrder.paymentMethod ===
                      PaymentMethod.K_PAY
                    ? '카카오 페이'
                    : reservation.paymentOrder.paymentMethod ===
                      PaymentMethod.N_PAY
                    ? '네이버 페이'
                    : '-',
              },
            ].map((e, index) => (
              <div
                className="flex justify-between items-center text-sm"
                key={index}
              >
                <div className="text-gray-600">{e.label}</div>
                <div className="text-gray-900">{e.content}</div>
              </div>
            ))}
            <div className="flex justify-between text-sm text-right">
              <div className="text-gray-600">
                {reservation.paymentOrder.paymentMethod ===
                  PaymentMethod.BANK && '환불 계좌 정보 '}
              </div>

              <div className="text-gray-900 space-y-1">
                {reservation.paymentOrder.paymentMethod ===
                  PaymentMethod.BANK && (
                  <>
                    <p>계좌 환불</p>
                    <p>
                      {reservation.paymentOrder.refundBank}{' '}
                      {reservation.paymentOrder.refundAccount}{' '}
                      {reservation.paymentOrder.depositor}
                    </p>
                    <p
                      className="cursor-pointer underline text-gray-500"
                      onClick={() => setIsOpenReceipt(true)}
                    >
                      현금영수증 요청
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="h-3 bg-gray-50" />

        <div className="p-6">
          {/* <div className="px-1">
            <FroalaEditorView model={description?.content} />
          </div> */}
          {reservation.state === ReservationState.RESERVATION_CONFIRMATION &&
            !isImpossibilityReservation && (
              <Button
                text="예약취소 요청"
                className="outlined-black w-full mt-4"
                disabled={isImpossibilityReservation}
                onClick={() => setIsOpen(true)}
              />
            )}
        </div>

        <div className="h-3 bg-gray-50" />

        <div className="p-6">
          <h3 className="text-lg font-semibold pb-3">주소 및 문의사항</h3>
          <div className="pb-6">
            <p className="text-gray-600">주소</p>
            <p className="font-medium my-0.5 text-gray-800">
              경기 가평군 상면 윗다원길 65-142
            </p>
          </div>
          <div>
            <p className="text-gray-600">문의사항</p>
            <p className="font-medium my-0.5 text-gray-800">010-7363-5540</p>
          </div>
        </div>
      </div>
    </>
  );
};
