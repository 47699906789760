import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { useAuth } from '../../hooks';
import { TopBar } from '../../components/TopBar';
import { PhoneNumber } from '../../components/PhoneNumber';
import { Checkbox } from '../../components/Checkbox';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

interface FormValues {
  name: string;
  email: string;
  password: string;
  verifyPassword: string;
  phoneNumber: string;
  termsOfService: boolean;
  privacyPolicy: boolean;
  thirdPartyConsent: boolean;
  marketingTerms: boolean;
}

export const SignupPage = () => {
  const { signup } = useAuth();
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const formSchema = Yup.object().shape({
    name: Yup.string().required('이름을 입력해주세요.'),
    email: Yup.string()
      .required('이메일을 입력해주세요')
      .email('이메일 형식이 아닙니다.'),
    password: Yup.string()
      .required('영문, 숫자포함 8자리를 입력해주세요.')
      .min(8, '최소 8자 이상 가능합니다')
      .max(15, '최대 15자 까지만 가능합니다')
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"])[0-9a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]{8,15}$/,
        '영문 숫자포함 8자리를 입력해주세요.'
      ),
    verifyPassword: Yup.string()
      .required('영문, 숫자포함 8자리를 입력해주세요.')
      .oneOf([Yup.ref('password')], '패스워드가 일치하지 않습니다.'),
  });

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <TopBar text="회원가입" backButton={true} />

      <form
        className="p-6 flex flex-col space-y-4"
        onSubmit={handleSubmit((data) => {
          const { verifyPassword, phoneNumber, ...user } = data;
          if (data.password !== verifyPassword) {
            alert('비밀번호가 다릅니다. 다시 확인해주세요.');
          } else {
            signup({
              phoneNumber: phoneNum,
              ...user,
            });
          }
        })}
      >
        <TextField
          label="이름"
          type="name"
          placeholder="이름을 입력해주세요."
          helper={errors.name?.message}
          {...register('name', { required: '이름을 입력해주세요' })}
        />

        <TextField
          label="이메일"
          type="email"
          placeholder="이메일을 입력해주세요."
          helper={errors.email?.message}
          {...register('email', { required: '이메일을 입력해주세요' })}
        />

        <TextField
          type="password"
          label="비밀번호"
          placeholder="영문 숫자 포함 8자리를 입력해주세요."
          helper={errors.password?.message}
          {...register('password', {
            required: '비밀번호를 입력해주세요',
            minLength: { value: 8, message: '최소 8자 이상 가능합니다' },
            maxLength: { value: 15, message: '최대 15자 까지만 가능합니다' },
          })}
        />

        <TextField
          type="password"
          label="비밀번호 재입력"
          placeholder="비밀번호를 재입력해주세요."
          helper={errors.verifyPassword?.message}
          {...register('verifyPassword', {
            required: '비밀번호 재입력해주세요',
            minLength: { value: 8, message: '최소 8자 이상 가능합니다' },
            maxLength: { value: 15, message: '최대 15자 까지만 가능합니다' },
          })}
        />

        <PhoneNumber
          label="휴대폰 인증"
          phoneNum={phoneNum}
          setPhoneNum={setPhoneNum}
          isVerified={isVerified}
          setIsVerified={setIsVerified}
        />

        <div className="space-y-4 font-semibold">
          <Checkbox
            label="전체동의"
            onChange={() => {
              const allChecked =
                watch('termsOfService') &&
                watch('privacyPolicy') &&
                watch('thirdPartyConsent') &&
                watch('marketingTerms');

              setValue('termsOfService', !allChecked);
              setValue('privacyPolicy', !allChecked);
              setValue('thirdPartyConsent', !allChecked);
              setValue('marketingTerms', !allChecked);
            }}
            checked={
              watch('termsOfService') &&
              watch('privacyPolicy') &&
              watch('thirdPartyConsent') &&
              watch('marketingTerms')
            }
          />
        </div>

        <div className="space-y-4 pb-8">
          <div className="flex items-center space-x-1">
            <Checkbox
              label="(필수)"
              {...register('termsOfService')}
              checked={watch('termsOfService')}
            />
            <a
              href="https://prairie-porcupine-6b9.notion.site/1a99dfc8163b4030afa18dc83ac58e6f?pvs=4"
              target="_blank"
              className="text-sm text-gray-800 font-semibold"
              rel="noreferrer"
            >
              이용약관에 동의
            </a>
          </div>
          <div className="flex items-center space-x-1">
            <Checkbox
              label="(필수)"
              {...register('privacyPolicy')}
              checked={watch('privacyPolicy')}
            />
            <a
              href="https://prairie-porcupine-6b9.notion.site/31a745c76f114d3aae32b7fb3cc9ed61?pvs=4"
              target="_blank"
              className="text-sm text-gray-800 font-semibold"
              rel="noreferrer"
            >
              개인정보 취급방침 동의
            </a>
          </div>

          {/* <Checkbox
            label="(필수) 제3자 동의"
            {...register('thirdPartyConsent')}
            checked={watch('thirdPartyConsent')}
          /> */}

          <Checkbox
            label="(선택) 마케팅 약관 동의"
            {...register('marketingTerms')}
            checked={watch('marketingTerms')}
          />
        </div>

        <Button
          text="가입하기"
          className="filled-brand-1"
          disabled={
            !watch('name') ||
            !watch('email') ||
            !watch('password') ||
            !watch('verifyPassword') ||
            !watch('termsOfService') ||
            !watch('privacyPolicy') ||
            !isVerified ||
            watch('password') !== watch('verifyPassword')
          }
        />
      </form>
    </>
  );
};
