import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { Select } from '../../../components/Select';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { TopBar } from '../../../components/TopBar';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { adminFetcher } from '../../../plugins/react-query';
import {
  CashReceiptUserType,
  PaymentMethod,
  PaymentOrderState,
  Reservation,
  ReservationState,
  Room,
} from '../../../types';
import { ReservationCancelModal } from '../../components/modal/ReservationCancelModal';
import { ReservationSmsModal } from '../../components/modal/ReservationSmsModal';
import { ReservationStatusModal } from '../../components/modal/ReservationStatusModal';
import moment from 'moment-timezone';
import { popupState } from '../../../plugins/ridge';
import { adminApi } from '../../../plugins/axios';
import { toast } from 'react-toastify';

interface FormValues {
  checkIn?: string;
  checkOut?: string;
  reservationName?: string;
  reservationEmail?: string;
  reservationPhoneNumber?: string;
  adult?: number;
  child?: number;
  infant?: number;
  memo?: string;
  roomId?: number;
}

export const ReservationDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [reservationStatus, setReservationStatus] = useState<boolean>(false);
  const [reservationCancel, setReservationCancel] = useState<boolean>(false);
  const [reservationSms, setReservationSms] = useState<boolean>(false);
  const [startedAt, setStartedAt] = useState<string>();
  const [endedAt, setEndedAt] = useState<string>();
  const [, setCheckRoomId] = useState<number>();

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const { data: reservation, refetch } = useQuery<Reservation>(
    `/admin/reservations/${id}`,
    adminFetcher,
    {
      keepPreviousData: true,
      enabled: !!id,
    }
  );

  // 선택한 날짜가 있는 경우 선택한 날짜의 예약 가능한 객실 불러오기
  const { data: rooms } = useQuery<Room[]>(
    `/admin/rooms/schedule/${reservation?.roomTypeId}?checkIn=${moment(
      startedAt
    )
      .tz('Asia/Seoul')
      .toISOString()}&checkOut=${moment(endedAt)
      .tz('Asia/Seoul')
      .toISOString()}`,
    adminFetcher,
    {
      enabled: !!reservation,
    }
  );

  useEffect(() => {
    if (!reservation) return;
    setValue('reservationName', reservation.reservationName);
    setValue('reservationEmail', reservation.reservationEmail);
    setValue('reservationPhoneNumber', reservation.reservationPhoneNumber);
    setValue('adult', reservation.adult);
    setValue('child', reservation.child);
    setValue('infant', reservation.infant);
    setValue('memo', reservation.memo);
    setValue('roomId', reservation.roomId);
    setStartedAt(
      moment(reservation?.checkIn).tz('Asia/Seoul').format().split('T')[0]
    );
    setEndedAt(
      moment(reservation?.checkOut).tz('Asia/Seoul').format().split('T')[0]
    );
    setCheckRoomId(reservation.roomId);
  }, [setValue, reservation]);

  // 옵션 상품 금액
  const roomOptionPrice = reservation?.paymentOrder?.orderRoomOptions?.map(
    (orderRoomOption) => {
      return Number(orderRoomOption.quantity) * orderRoomOption.price;
    }
  );

  // 선택한 옵션 총 금액
  const roomOptionTotalPrice = roomOptionPrice?.reduce((stack, el) => {
    return stack + el;
  }, 0);

  // 예약 날짜 변경
  const reservationChangeCheckIn = async (data: FormValues) => {
    try {
      data.checkIn = moment(startedAt).toISOString();
      data.checkOut = moment(endedAt).toISOString();
      data.roomId = watch('roomId');
      await adminApi.patch(
        `/admin/reservations/checkIn/${reservation?.id}`,
        data
      );
      refetch();
      toast.success('변경 사항이 저장되었습니다.');
    } catch (e) {
      toast.error('예약 날짜 변경 오류');
    }
  };

  // 예약 정보 변경
  const reservationUpdate = async (data: FormValues) => {
    try {
      await adminApi.patch(`/admin/reservations/info/${reservation?.id}`, data);
      refetch();
      toast.success('변경 사항이 저장되었습니다.');
    } catch (e) {
      toast.error('예약 정보 변경 오류');
    }
  };

  // 예약 확정
  const confirmReservation = async () => {
    try {
      await adminApi.patch(`/admin/reservations/confirm/${reservation?.id}`);
      refetch();
      toast.success('예약이 확정되었습니다.');
    } catch (e) {
      toast.error('예약 확정 오류');
    }
  };

  if (!reservation) {
    return <></>;
  }
  return (
    <div>
      <ReservationStatusModal
        reservation={reservation}
        open={reservationStatus}
        refetch={() => refetch()}
        onClose={() => setReservationStatus(false)}
      />
      <ReservationCancelModal
        open={reservationCancel}
        reservation={reservation}
        refetch={() => refetch()}
        onClose={() => setReservationCancel(false)}
      />
      <ReservationSmsModal
        reservation={reservation}
        open={reservationSms}
        onClose={() => setReservationSms(false)}
      />
      <TopBar text="예약 상세" backButton />
      <div className="p-6 bg-gray-50 space-y-3">
        <div className="flex justify-between items-end">
          <p className="text-lg text-red-500">
            {reservation.state === ReservationState.CANCELED
              ? '취소완료'
              : reservation.state === ReservationState.CANCELLATION_REQUEST
              ? '취소신청'
              : reservation.state ===
                ReservationState.APPLICATION_FOR_RESERVATION
              ? '예약신청'
              : reservation.state === ReservationState.RESERVATION_CONFIRMATION
              ? '예약완료'
              : reservation.state === ReservationState.BEFORE_DEPOSIT
              ? '입금 전'
              : ''}
          </p>
          <button
            onClick={() => setReservationStatus(true)}
            className="text-blue-500"
          >
            수동변경
          </button>
        </div>
        <div className="grid grid-cols-3 gap-x-3">
          <Button
            text="예약취소"
            className="filled-brand-1 text-sm h-10"
            onClick={() => setReservationCancel(true)}
            disabled={
              reservation.state === ReservationState.USED ||
              reservation.state === ReservationState.CANCELED
            }
          />
          <Button
            text="예약확정"
            className="filled-brand-1 text-sm h-10"
            disabled={
              reservation.state === ReservationState.RESERVATION_CONFIRMATION ||
              reservation.state === ReservationState.USED ||
              reservation.state === ReservationState.CANCELLATION_REQUEST ||
              reservation.state === ReservationState.CANCELED
            }
            onClick={() =>
              popupState.set({
                title: '예약 확정하기',
                body: `
  ${utcToLocalFormat(
    reservation.checkIn,
    MomentFormat.YMD
  )} - ${utcToLocalFormat(reservation.checkOut, MomentFormat.YMD)} ${
                  reservation?.roomTitle
                } 예약을 확정하시겠습니까?`,
                primaryText: '확정하기',
                primaryClick: () => confirmReservation(),
              })
            }
          />
          <Button
            text="문자발송"
            className="filled-brand-1 text-sm h-10"
            onClick={() => setReservationSms(true)}
          />
        </div>
      </div>
      <div className="p-6 space-y-3">
        <h3 className="text-xl font-semibold">날짜</h3>
        <div className="grid grid-cols-3 gap-y-1">
          <div className="col-span-3">
            <TextField
              type="date"
              value={startedAt}
              onChange={(e) => setStartedAt(e.target.value)}
            />
          </div>
          <div className="col-span-3">
            <TextField
              type="date"
              value={endedAt}
              onChange={(e) => setEndedAt(e.target.value)}
            />
          </div>
          <div className="col-span-3">
            <Select
              value={watch('roomId')}
              onChange={(e) => {
                setValue('roomId', Number(e.target.value));
                setCheckRoomId(Number(e.target.value));
              }}
            >
              {rooms?.map((room) => (
                <option
                  key={room.id}
                  disabled={
                    !room.timeslots.length || room.timeslots.length === 0
                  }
                  selected={reservation.roomId === room.id}
                  value={room.id}
                >
                  {room.title}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <Button
          text="변경하기"
          className="filled-brand-1 w-full text-sm"
          disabled={
            moment(startedAt).toISOString() === reservation?.checkIn &&
            watch('roomId') === reservation.roomId
          }
          onClick={handleSubmit(async (data) => {
            popupState.set({
              title: '날짜 및 룸을 변경하시겠습니까?',
              body: `
              ${utcToLocalFormat(
                reservation.checkIn,
                MomentFormat.YMD
              )} -  ${utcToLocalFormat(
                reservation.checkOut,
                MomentFormat.YMD
              )} ${reservation.roomTitle} 을
              ${utcToLocalFormat(
                startedAt || '',
                MomentFormat.YMD
              )} - ${utcToLocalFormat(endedAt || '', MomentFormat.YMD)} ${
                rooms?.find((room) => room.id === watch('roomId'))?.title
              } 변경하시겠습니까?`,
              primaryText: '변경하기',
              primaryClick: () => reservationChangeCheckIn(data),
            });
          })}
        />
      </div>
      <div className="pt-3 bg-gray-50" />
      <div className="p-6 space-y-3">
        <h3 className="text-lg font-medium">예약자 정보</h3>
        <TextField
          label="이름"
          placeholder="예약자 이름을 입력해주세요."
          helper={errors.reservationName?.message}
          {...register('reservationName', {
            required: '예약자 이름을 입력해주세요.',
          })}
        />
        <TextField
          label="핸드폰 번호"
          placeholder="핸드폰 번호를 입력해주세요."
          helper={errors.reservationPhoneNumber?.message}
          {...register('reservationPhoneNumber', {
            required: '핸드폰 번호를 입력해주세요.',
          })}
        />
        <TextField
          label="이메일"
          placeholder="이메일을 입력해주세요."
          helper={errors.reservationEmail?.message}
          {...register('reservationEmail', {
            required: '이메일을 입력해주세요.',
          })}
        />
        <h3 className="text-lg font-medium pt-5">인원 (기본 2인, 최대 4인)</h3>
        <div className="grid grid-cols-3 gap-5">
          <div className="place-self-center w-full">
            {reservation?.roomTypeId === 2 ? (
              <Select
                label="성인(최대 2인)"
                value={watch('adult')}
                onChange={(e) => {
                  if (
                    Number(e.target.value) === 2 &&
                    !watch('child') &&
                    !watch('infant')
                  ) {
                    setValue('adult', Number(e.target.value));
                  } else if (
                    Number(e.target.value) === 2 &&
                    (watch('child') === 1 || watch('infant') === 1)
                  ) {
                    setValue('adult', Number(e.target.value));
                    setValue('child', Number(0));
                    setValue('infant', Number(0));
                  } else if (
                    (Number(e.target.value) === 1 ||
                      Number(e.target.value) === 2) &&
                    (watch('child') === 1 || watch('infant') === 1)
                  ) {
                    setValue('adult', Number(e.target.value));
                  } else {
                    setValue('adult', Number(e.target.value));
                    setValue('child', Number(0));
                    setValue('infant', Number(0));
                  }
                }}
              >
                <option value={1}>1명</option>
                <option value={2}>2명</option>
              </Select>
            ) : (
              <Select
                label="성인(최대 4인)"
                value={watch('adult')}
                onChange={(e) => {
                  if (
                    Number(e.target.value) === 4 &&
                    !watch('child') &&
                    !watch('infant')
                  ) {
                    setValue('adult', Number(e.target.value));
                  } else if (
                    Number(e.target.value) === 3 &&
                    watch('child') === 1 &&
                    watch('infant') === 1
                  ) {
                    setValue('adult', Number(e.target.value));
                    setValue('child', Number(0));
                    setValue('infant', Number(0));
                  } else if (
                    Number(e.target.value) === 4 &&
                    (watch('child') === 1 || watch('infant') === 1)
                  ) {
                    setValue('adult', Number(e.target.value));
                    setValue('child', Number(0));
                    setValue('infant', Number(0));
                  } else if (
                    (Number(e.target.value) === 2 ||
                      Number(e.target.value) === 3) &&
                    (watch('child') === 1 || watch('infant') === 1)
                  ) {
                    setValue('adult', Number(e.target.value));
                  } else {
                    setValue('adult', Number(e.target.value));
                    setValue('child', Number(0));
                    setValue('infant', Number(0));
                  }
                }}
              >
                <option value={2}>2명</option>
                <option value={3}>3명</option>
                <option value={4}>4명</option>
              </Select>
            )}
          </div>

          <div className="place-self-center w-full">
            {reservation?.roomTypeId === 2 ? (
              <Select
                label="아동(24개월~7세)"
                value={watch('child')}
                onChange={(e) => setValue('child', Number(e.target.value))}
              >
                <option value={0} disabled hidden selected>
                  인원수
                </option>
                <option value={0}>0명</option>
                {watch('adult') === 1 && !watch('infant') ? (
                  <option value={1}>1명</option>
                ) : watch('adult') === 1 && watch('infant') === 1 ? (
                  ''
                ) : watch('adult') === 2 && !watch('infant') ? (
                  ''
                ) : watch('adult') === 2 && watch('infant') === 1 ? (
                  ''
                ) : (
                  ''
                )}
              </Select>
            ) : (
              <Select
                label="아동(24개월~7세)"
                value={watch('child')}
                onChange={(e) => setValue('child', Number(e.target.value))}
              >
                <option value={0} disabled hidden selected>
                  인원수
                </option>
                <option value={0}>0명</option>
                {watch('adult') === 2 && !watch('infant') ? (
                  <>
                    <option value={1}>1명</option>
                    <option value={2}>2명</option>
                  </>
                ) : watch('adult') === 2 && watch('infant') === 1 ? (
                  <option value={1}>1명</option>
                ) : watch('adult') === 3 && !watch('infant') ? (
                  <option value={1}>1명</option>
                ) : (
                  ''
                )}
              </Select>
            )}
          </div>

          <div className="place-self-center w-full">
            {reservation?.roomTypeId === 2 ? (
              <Select
                label="영아(24개월 미만)"
                className="place-self-center w-full"
                value={watch('infant')}
                onChange={(e) => setValue('infant', Number(e.target.value))}
              >
                <option value={0} disabled hidden selected>
                  인원수
                </option>
                <option value={0}>0명</option>
                {watch('adult') === 1 && !watch('child') ? (
                  <option value={1}>1명</option>
                ) : watch('adult') === 1 && watch('child') === 1 ? (
                  ''
                ) : watch('adult') === 2 && !watch('child') ? (
                  ''
                ) : watch('adult') === 2 && watch('child') === 1 ? (
                  ''
                ) : (
                  ''
                )}
              </Select>
            ) : (
              <Select
                label="영아(24개월 미만)"
                className="place-self-center w-full"
                value={watch('infant')}
                onChange={(e) => setValue('infant', Number(e.target.value))}
              >
                <option value={0} disabled hidden selected>
                  인원수
                </option>
                <option value={0}>0명</option>
                {watch('adult') === 2 && !watch('child') ? (
                  <>
                    <option value={1}>1명</option>
                    <option value={2}>2명</option>
                  </>
                ) : watch('adult') === 2 && watch('child') === 1 ? (
                  <option value={1}>1명</option>
                ) : watch('adult') === 3 && !watch('child') ? (
                  <option value={1}>1명</option>
                ) : (
                  ''
                )}
              </Select>
            )}
          </div>
        </div>

        <h3 className="text-lg font-medium pt-5">옵션사항</h3>
        {reservation?.paymentOrder?.orderRoomOptions.map((orderRoomOption) => (
          <Checkbox
            key={orderRoomOption.id}
            disabled
            checked={true}
            label={`${orderRoomOption.title} (${orderRoomOption.quantity}) / ${(
              orderRoomOption.price * orderRoomOption.quantity
            )
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`}
          />
        ))}

        <div className="space-y-3 pt-4">
          <TextArea
            label="요청사항"
            placeholder="요청사항을 입력해주세요."
            className="h-36"
            value={reservation.requestedTerm}
            disabled
          />
          <TextArea
            label="관리자 메모"
            placeholder="관리자 메모를 입력해주세요."
            className="h-36"
            {...register('memo')}
          />
        </div>
        <Button
          text="저장하기"
          className="filled-brand-1 w-full text-sm"
          onClick={handleSubmit(async (data) => {
            popupState.set({
              title: '변경사항을 저장하시겠습니까?',
              primaryText: '저장하기',
              primaryClick: () => reservationUpdate(data),
            });
          })}
        />
      </div>

      <div className="p-6">
        <h3 className="text-xl font-semibold pb-3 border-b mb-3">결제 정보</h3>
        <div className="space-y-2">
          <div className="flex justify-between items-center text-lg font-medium">
            <div className="text-gray-600 font-normal text-sm">
              총 결제 금액
            </div>
            <div className="">
              {reservation.paymentOrder.finalPrice
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
            </div>
          </div>
          {[
            {
              label: '객실 요금',
              content:
                (reservation.paymentOrder.roomPrice
                  ? reservation.paymentOrder.roomPrice
                  : reservation.paymentOrder.totalPrice
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
            },
            {
              label: '추가 인원 요금',
              content:
                (reservation.paymentOrder.additionalPersonAmount || 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
            },
            {
              label: '추가옵션',
              content:
                (roomOptionTotalPrice || 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
            },
            {
              label: '적립금 사용 금액',
              content:
                reservation.paymentOrder.usedPoint
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
            },
            {
              label: '적립금 적립 예정',
              content:
                reservation.paymentOrder.depositedPoint
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'P',
            },
            {
              label: '결제일시',
              content: utcToLocalFormat(
                reservation.paymentOrder.paidAt,
                MomentFormat.YYYYMMDDHmm
              ),
            },
            {
              label: '결제방법',
              content:
                reservation.paymentOrder.paymentMethod === PaymentMethod.CARD
                  ? '카드결제'
                  : reservation.paymentOrder.paymentMethod ===
                    PaymentMethod.BANK
                  ? '무통장 입금'
                  : reservation.paymentOrder.paymentMethod ===
                    PaymentMethod.K_PAY
                  ? '카카오 페이'
                  : reservation.paymentOrder.paymentMethod ===
                    PaymentMethod.N_PAY
                  ? '네이버 페이'
                  : '-',
            },
          ].map((e, i) => (
            <div className="flex justify-between items-center text-sm" key={i}>
              <div className="text-gray-600">{e.label}</div>
              <div className="text-gray-900">{e.content}</div>
            </div>
          ))}

          {reservation.paymentOrder.paymentMethod === PaymentMethod.BANK && (
            <>
              <div className="flex justify-between text-sm text-right">
                <div className="text-gray-600">환불 계좌 정보</div>
                <div className="text-gray-900 space-y-1">
                  <p>계좌 환불</p>
                  <p>
                    {reservation.paymentOrder.refundBank}{' '}
                    {reservation.paymentOrder.refundAccount}{' '}
                    {reservation.paymentOrder.depositor}
                  </p>
                </div>
              </div>
              <div className="flex justify-between text-sm text-right">
                <div className="text-gray-600">현금영수증</div>
                <div className="text-gray-900">
                  {reservation.paymentOrder.cashReceiptUserType ===
                  CashReceiptUserType.ENTREPRENEUR
                    ? '사업자'
                    : '개인'}{' '}
                  / {reservation.paymentOrder.cashReceiptName}{' '}
                  {reservation.paymentOrder.cashReceiptNumber}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="p-6">
        {(reservation.paymentOrder.state === PaymentOrderState.REFUND ||
          reservation.paymentOrder.state === PaymentOrderState.CANCELED) && (
          <>
            <h3 className="text-xl font-semibold pb-3 border-b mb-3">
              취소 정보
            </h3>
            <div className="space-y-2">
              <div className="flex justify-between items-center text-lg font-medium">
                <div className="text-gray-600 font-normal text-sm">
                  총 환불 금액
                </div>
                <div className="">
                  {reservation.paymentOrder.finalPrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
                </div>
              </div>
              {[
                {
                  label: '결제 취소 금액',
                  content:
                    (reservation.paymentOrder.cancelledPrice || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
                },
                {
                  label: '취소 후 남은 금액 ( 부분 취소 시 남은 금액 )',
                  content:
                    (reservation.paymentOrder.remainPrice || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
                },
                {
                  label: '반환 적립금',
                  content:
                    (reservation.paymentOrder.returnedPoint || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'P',
                },
                {
                  label: '취소 신청 일시',
                  content: reservation.cancelRequestedAt
                    ? utcToLocalFormat(
                        reservation.cancelRequestedAt,
                        MomentFormat.YYYYMMDDHmmss
                      )
                    : '-',
                },
                {
                  label: '취소일시',
                  content: utcToLocalFormat(
                    reservation.canceledAt,
                    MomentFormat.YYYYMMDDHmmss
                  ),
                },
              ].map((e, i) => (
                <div
                  className="flex justify-between items-center text-sm"
                  key={i}
                >
                  <div className="text-gray-600">{e.label}</div>
                  <div className="text-gray-900">{e.content}</div>
                </div>
              ))}

              {reservation.paymentOrder.paymentMethod ===
                PaymentMethod.BANK && (
                <div className="flex justify-between text-sm text-right">
                  <div className="text-gray-600">환불 처리 수단</div>
                  <div className="text-gray-900 space-y-1">
                    <p>계좌 환불</p>
                    <p>
                      {reservation.paymentOrder.refundBank}{' '}
                      {reservation.paymentOrder.refundAccount}{' '}
                      {reservation.paymentOrder.depositor}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
